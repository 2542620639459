import { React } from "react";

import { currencyFormatter, isNullOrUndefinedOrEmpty } from "../../../utils.js";
import { Grid } from "@mui/material";

import Card from "@mui/material/Card/Card.js";
import CardContent from "@mui/material/CardContent/CardContent.js";

import { IN_PROCESS, RESERVED, READY_TO_FULFILL, SOLD } from "../../../redux/constants.js"

const QUOTE_STATES = [RESERVED, IN_PROCESS, READY_TO_FULFILL, SOLD]


export const DashboardValue = ({ title, content, units }) => {
    return <Card variant={"outlined"} key={`${title}-value`}>
        <CardContent className="quantity">
            <h2>{title}</h2>
            <h3>{content}</h3>
            <p>{units || ""}</p>
        </CardContent>
    </Card>
}

export const DashboardMetric = ({ currentUser, salesReport, salesQuoteState, accessor }) => {
    if (isNullOrUndefinedOrEmpty(salesReport)) {
        return <DashboardValue key={`${salesQuoteState}-dashboard-metric`} title={salesQuoteState} content="-" />
    }
    let aggregate = 0;
    if (!isNullOrUndefinedOrEmpty(currentUser)) {
        const name = currentUser.first_name + " " + currentUser.last_name;
        aggregate = salesReport.reduce((currentTotal, entry) => {
            if (entry.sales_rep === name && entry.status === IN_PROCESS) {
                return currentTotal + entry.total;
            }
            return currentTotal;
        }, 0);

    } else {
        aggregate = salesReport.reduce((currentTotal, entry) => {
            if (entry.status === salesQuoteState) {
                return currentTotal + entry.total;
            }
            return currentTotal;
        }, 0);
    }

    if (accessor === "total") {
        return <DashboardValue
            key={`${salesQuoteState}-dashboard-metric`}
            title={salesQuoteState}
            content={`${aggregate}`}
            units={"units"}
        />
    }
    return <DashboardValue
        key={`${salesQuoteState}-dashboard-metric`}
        title={salesQuoteState}
        content={currencyFormatter.format(aggregate)}
    />
};


const AggregateStats = ({ currentUser, salesReport }) => {

    if (isNullOrUndefinedOrEmpty(salesReport)) {
        return <div><h3>---</h3></div>
    }

    return <Grid container className="aggregate" spacing={12}>
        {QUOTE_STATES.map((state, idx) => {
            return <Grid
                key={`${state}-dashboard-metric-${idx}-grid`}
                item xs={6}>
                <DashboardMetric
                    key={`${state}-dashboard-metric-${idx}`}
                    currentUser={currentUser}
                    salesReport={salesReport}
                    salesQuoteState={state}
                    accessor={"total"}
                />
            </Grid>
        })}
    </Grid>
}

export default AggregateStats;
