import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppBar, Box, Toolbar } from "@mui/material";
import { Typography, Menu, MenuItem } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CreateAccountForm from "./forms/CreateAccount.js";
import {
    Home as HomeIcon,
    Store as StoreIcon,
    Logout as LogoutIcon,
    Settings as SettingsIcon,
    AttachMoney as AttachMoneyIcon,
} from "@mui/icons-material";
import { isNullOrUndefinedOrEmpty, userIsNotLoggedIn } from "../utils.js";
import { showPopup } from "../redux/notifications/Action.js";
import { memberIsAdmin } from "../utils/Roles.js";
import Login from "./forms/Login.js";
import Button from "@mui/material/Button/Button.js";
import CreateNewOrganization from "./forms/CreateNewOrganization.js";
import { ROUTES_ORGANIZATIONS_LIST, ROUTES_HOME, ROUTES_MY_ACCOUNT, ROUTES_INTEGRATIONS, ROUTES_INTEGRATIONS_METRC_LIST } from "../utils/Constants.js";
import { SSO_DO_LOGOUT } from "../redux/constants.js";
import { logout, userLoggedIn } from "../redux/user/Action.js";


const AccountStatus = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const profile = useSelector(state => state.userReducer.loggedInUser.profile);

    const firstName = loggedInUser && (loggedInUser.first_name !== null) ? loggedInUser.first_name : "";

    const whenLoggedIn = <>
        <Link id="welcome-user" className="menubar" to={ROUTES_MY_ACCOUNT}>Welcome, {firstName}</Link>&nbsp;&nbsp;
        {
            organizations.length === 0 ?
                <CreateNewOrganization displayText={"Create Organization"} /> : <CreateAccountForm
                    displayText="Add another user"
                    isAdditional={true} />
        }
        <Button id="logout" variant="outlined" className="secondary" onClick={() => {
            dispatch(logout()).then(() => {
                dispatch(userLoggedIn({ user: null, organizationUsers: [], organizations: [] }));
                navigate("/");
                dispatch(showPopup(SSO_DO_LOGOUT));
            });
        }}>
            <LogoutIcon className="logout-icon" />&nbsp; Logout
        </Button>
    </>

    const whenLoggedOut = (
        <>
            <CreateAccountForm />
            <Login />
        </>
    )

    return userIsNotLoggedIn(loggedInUser) ? whenLoggedOut : whenLoggedIn;
}


const NavOptions = () => {

    const [integrationsAnchorEl, setIntegrationsAnchorEl] = useState(null);
    const openIntegrations = Boolean(integrationsAnchorEl);
    const location = useLocation();
    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const profile = useSelector(state => state.userReducer.loggedInUser.profile);
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);
    const userIsAdmin = memberIsAdmin(loggedInUser, organizationUsers, selectedOrganization);

    const handleIntegrationsClick = (event) => {
        setIntegrationsAnchorEl(event.currentTarget);
    };
    const handleIntegrationsClose = () => {
        setIntegrationsAnchorEl(null);
    };

    const getIntegrationsButton = () => {
        if (userIsNotLoggedIn(loggedInUser) || isNullOrUndefinedOrEmpty(organizationUsers) || isNullOrUndefinedOrEmpty(selectedOrganization)) {
            return "";
        }
        if (isNullOrUndefinedOrEmpty(profile) || profile.waitlist) {
            return "";
        }
        if (!userIsAdmin) {
            return "";
        }
        return <Box>
            <Button onClick={handleIntegrationsClick} className={"navlink" + (ROUTES_INTEGRATIONS === location.pathname || ROUTES_INTEGRATIONS_METRC_LIST === location.pathname ? " selected" : "")} id="go-integrations">
                <SettingsIcon />
                <Typography>Integrations</Typography>
            </Button>
            <Menu open={openIntegrations} anchorEl={integrationsAnchorEl} onClose={handleIntegrationsClose}>
                <MenuItem>
                    <Link style={{ width: "100%", height: "100%" }} to={ROUTES_INTEGRATIONS} className="navlink" onClick={handleIntegrationsClose} id="go-integrations-setup">
                        Setup
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link style={{ width: "100%", height: "100%" }} to={ROUTES_INTEGRATIONS_METRC_LIST} className="navlink" onClick={handleIntegrationsClose} id="go-integrations-metrc-integrations">
                        Metrc Integrations
                    </Link>
                </MenuItem>
            </Menu>
        </Box>
    }


    if (userIsNotLoggedIn(loggedInUser)) {
        return <Box>
            <Link to={ROUTES_HOME} className="navlink" id="go-home">
                <HomeIcon />
                <Typography>Home</Typography>
            </Link>
        </Box>;
    }
    return <>
        <Box>
            <Link to={ROUTES_HOME} className={"navlink"} id="go-home">
                <HomeIcon />
                <Typography>Home</Typography>
            </Link>
        </Box>
        {isNullOrUndefinedOrEmpty(profile) || profile.waitlist ? "" : <Box>
            <a href={process.env.REACT_APP_WHOLESALE_URL} className={"navlink"} id="go-wholesale">
                <AttachMoneyIcon />
                <Typography>Wholesale</Typography>
            </a>
        </Box>}
        {isNullOrUndefinedOrEmpty(profile) || profile.waitlist ? "" : <Box>
            <Link to={ROUTES_ORGANIZATIONS_LIST} className={"navlink" + (ROUTES_ORGANIZATIONS_LIST === location.pathname ? " selected" : "")} id="go-organizations">
                <StoreIcon />
                <Typography>Organizations</Typography>
            </Link>
        </Box>}
        {getIntegrationsButton()}
    </>
}


export const MainMenu = () => {
    const [openIntegrations, setOpenIntegrations] = useState(false);

    return <AppBar position="fixed" className={"top-rail"}>
        <Toolbar disableGutters>
            <Box>
                <img src="/canold-logo.webp" />
                <div className="beta">Public Beta</div>
            </Box>
            <NavOptions
                openIntegrations={openIntegrations}
                setOpenIntegrations={setOpenIntegrations}
            />
            <Box sx={{ flexGrow: 1, display: { xs: "flex" } }}></Box>
            <AccountStatus />
        </Toolbar>
    </AppBar>
}


export default MainMenu;
