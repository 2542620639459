import './stylesheets/main.css';
import React, {useState, useEffect} from "react";
import {useNavigate, BrowserRouter as Router, Routes, Route, useSearchParams} from "react-router-dom";
import '@fontsource/roboto';
import "react-table-v6/react-table.css"
import {Container} from "@mui/material";
import {Provider, useDispatch, useSelector} from "react-redux";
import {createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import Reducers from "./redux/Reducers.js";
import {SnackbarProvider} from "notistack";
import {composeWithDevTools} from "redux-devtools-extension";
import {
    fetchOrganizations, fetchOrganizationUserGroupsForUser, logout,
} from "./redux/user/Action.js";
import {fetchOrganizationUsers} from './redux/user/Action.js';
import Notifier from "./components/notifications/Notifier.js";
import ForgotPassword from "./views/ForgotPassword.js";
import ResetPassword from "./views/ResetPassword.js";
import MainMenu from "./components/MainMenu.js";
import OrganizationList from "./views/organizations/List.js";
import OrganizationDetail from "./views/organizations/Detail.js";
import IntegrationSetup from "./views/integrations/Setup.js"
import MetrcIntegrationList from "./views/integrations/metrc/List.js"
import MetrcIntegrationDetail from "./views/integrations/metrc/Detail.js"
import MetrcIntegrationSetup from "./views/integrations/metrc/Setup.js"
import MyAccount from "./views/MyAccount.js";
import TermsOfService from "./views/TermsOfService.js";
import Home from "./views/home/Home.js"
import {hasPendingInvitations, isNullOrUndefinedOrEmpty, userIsNotLoggedIn} from "./utils.js";
import {showInformationalPopup, showPopup} from "./redux/notifications/Action.js";
import {Popup} from "./components/notifications/Popup.js"
import {selectOrganization} from "./redux/models/organizations/Action.js";

import {
    ROUTES_HOME,
    ROUTES_FORGOT_PASSWORD,
    ROUTES_ORGANIZATIONS_LIST,
    ROUTES_ORGANIZATIONS_DETAIL,
    ROUTES_ORGANIZATIONS_DETAIL_BANK_ACCOUNTS,
    ROUTES_ORGANIZATIONS_DETAIL_LOGO,
    ROUTES_ORGANIZATIONS_DETAIL_USERS_AND_ROLES,
    ROUTES_INTEGRATIONS,
    ROUTES_INTEGRATIONS_METRC_SETUP,
    ROUTES_INTEGRATIONS_METRC_LIST,
    ROUTES_INTEGRATIONS_METRC_DETAIL,
    ROUTES_INTEGRATIONS_METRC_DETAIL_BACKFILLS,
    ROUTES_INTEGRATIONS_METRC_DETAIL_AUTHENTICATION,
    ROUTES_MY_ACCOUNT,
    ROUTES_RESET_PASSWORD,
    BANK,
    LOGO,
    ROLES,
    BACKFILLS,
    AUTHENTICATION,
} from "./utils/Constants.js";
import {SSO_ACTIONS, SSO_DO_LOGOUT, SSO_RESET_PASSWORD} from "./redux/constants.js";
import {fetchUserProfile, fetchCurrentUser, userLoggedIn, loggedInUserIsLoading} from "./redux/user/Action.js";
import {fetchAccountStatuses} from './redux/models/integrations/Action.js';
import SetupWizard from "./components/SetupWizard.js";
import {getCookie} from './utils.js';
import Loading from "./components/elements/Loading.js";
import Waitlist from "./views/home/Waitlist.js";

export function configureStore(InitialState) {
    const Store = createStore(
        Reducers,
        InitialState,
        composeWithDevTools(applyMiddleware(thunk))
    );
    return Store;
}


const Main = () => {
    const navigate = useNavigate();

    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user || {})
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const userProfile = useSelector(state => state.userReducer.loggedInUser.profile);
    const [isLoading, setIsLoading] = useState(true); // useSelector(state => state.userReducer.loading);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);

    const dispatch = useDispatch();
    const [params, setParams] = useSearchParams()
    const [actionId, setActionId] = useState(params.get("a"));
    const [errorMessage, setErrorMessage] = useState(params.get("m"));
    const action = SSO_ACTIONS[actionId];
    const organizationId = isNullOrUndefinedOrEmpty(getCookie("organizationId")) ? null : parseInt(getCookie("organizationId"));
    const ssoCookie = getCookie("sso");

    useEffect(() => {
        const abortController = new AbortController();
        console.log("Mounting...");
        if (!isNullOrUndefinedOrEmpty(ssoCookie)) {
            console.log("Sending requests....");
            setIsLoading(() => true);
            dispatch(fetchCurrentUser(abortController)).then((currentUser) => {
                Promise.all([
                    dispatch(fetchUserProfile(currentUser, abortController)),
                    dispatch(fetchOrganizationUserGroupsForUser(currentUser, abortController)),
                    dispatch(fetchOrganizationUsers(null, currentUser.id, abortController)),
                    dispatch(fetchAccountStatuses(abortController)),
                    dispatch(fetchOrganizations(null, abortController))
                ]).then(response => {
                    const [fetchedUserProfile, fetchedOrganizationUserGroups, fetchedOrganizationUsers, fetchedAccountStatuses, fetchedOrganizations] = response;
                    dispatch(userLoggedIn({
                        user: currentUser,
                        organizationUsers: fetchedOrganizationUsers,
                        organizations: fetchedOrganizations,
                        organizationUserGroups: fetchedOrganizationUserGroups,
                        profile: fetchedUserProfile,
                        accountStatuses: fetchedAccountStatuses
                    }));
                    setIsLoading(() => false);
                }).catch(err => {
                    if (err !== "ABORTED") {
                        setIsLoading(() => false);
                    }
                })
            }).catch(err => {
                if (err !== "ABORTED") {
                    setIsLoading(() => false);
                }
            });
        } else {
            setIsLoading(() => false);
        }

        if (params.has("m")) {
            params.delete("m");
            params.delete("a");
            setParams(params);
            dispatch(showPopup(action, errorMessage));
        } else if (params.has("a")) {
            setParams({...params});
            params.delete("a");
            if (action === SSO_RESET_PASSWORD) {
                navigate(ROUTES_RESET_PASSWORD);
            } else if (action === SSO_DO_LOGOUT) {
                dispatch(logout()).then(response => {
                    return dispatch(showPopup(action));
                }).catch(err => {
                })
            } else {
                dispatch(showPopup(action));
            }
        }
        return () => {
            abortController.abort();
        }
    }, [loggedInUser.id]);

    useEffect(() => {
        if (isNullOrUndefinedOrEmpty(organizations)) {
            return;
        }
        if (!isNullOrUndefinedOrEmpty(selectedOrganization) && parseInt(selectedOrganization.id) === parseInt(organizationId)) {
            return;
        }

        console.log("selected", selectedOrganization, "id", organizationId);
        if (organizationId) {
            const org = organizations.find(o => {
                return o.id === organizationId;
            });

            if (org) {
                dispatch(selectOrganization(org));
            }
        } else if (organizations.length !== 0) {
            dispatch(selectOrganization(organizations[0]));
        }
    }, [organizationId, loggedInUser.id]);

    useEffect(() => {
        if (isNullOrUndefinedOrEmpty(organizationUsers)) {
            return;
        }

        if (hasPendingInvitations(organizationUsers)) {
            dispatch(showInformationalPopup("Pending Invitations", <p>
                    You have pending invitations to existing organizations. Click "OK" to accept or delete them.
                </p>,
                () => {
                    navigate(ROUTES_ORGANIZATIONS_LIST);
                }
            ))
        }
    }, [loggedInUser.id, isNullOrUndefinedOrEmpty(organizationUsers)]);

    console.log("is loading?", isLoading);

    if (isLoading) {
        return <>
            <MainMenu/>
            <Container className="workspace">
                <Loading/>
            </Container>
        </>
    }


    if (userIsNotLoggedIn(loggedInUser)) {
        console.log(isLoading, loggedInUser);
        return <>
            <MainMenu/>
            <Routes>
                <Route path={ROUTES_HOME} element={<Waitlist/>}/>
                <Route path={ROUTES_FORGOT_PASSWORD} element={<ForgotPassword/>}/>
                <Route path={ROUTES_RESET_PASSWORD} element={<ResetPassword/>}/>
            </Routes>
        </>;
    }

    if (userProfile && !userProfile.has_accepted_tos) {
        return <TermsOfService loggedInUserProfile={userProfile}/>
    }

    return <>
        <MainMenu/>
        <Popup/>
        <SetupWizard
            loggedInUser={loggedInUser}
            loggedInUserProfile={userProfile}
        />
        <Routes>
            <Route path={ROUTES_HOME} element={<Waitlist/>}/>
            <Route path={ROUTES_FORGOT_PASSWORD} element={<ForgotPassword/>}/>
            <Route path={ROUTES_ORGANIZATIONS_LIST} element={<OrganizationList/>}/>
            <Route path={ROUTES_ORGANIZATIONS_DETAIL}
                   element={<OrganizationDetail/>}/>
            <Route path={ROUTES_ORGANIZATIONS_DETAIL_BANK_ACCOUNTS}
                   element={<OrganizationDetail tab={BANK}/>}/>
            <Route path={ROUTES_ORGANIZATIONS_DETAIL_LOGO}
                   element={<OrganizationDetail tab={LOGO}/>}/>
            <Route path={ROUTES_ORGANIZATIONS_DETAIL_USERS_AND_ROLES}
                   element={<OrganizationDetail tab={ROLES}/>}/>
            <Route path={ROUTES_INTEGRATIONS_METRC_SETUP}
                   element={<MetrcIntegrationSetup/>}/>
            <Route path={ROUTES_INTEGRATIONS_METRC_LIST}
                   element={<MetrcIntegrationList/>}/>
            <Route path={ROUTES_INTEGRATIONS_METRC_DETAIL}
                   element={<MetrcIntegrationDetail/>}/>
            <Route path={ROUTES_INTEGRATIONS_METRC_DETAIL_BACKFILLS}
                   element={<MetrcIntegrationDetail tab={BACKFILLS}/>}/>
            <Route path={ROUTES_INTEGRATIONS_METRC_DETAIL_AUTHENTICATION}
                   element={<MetrcIntegrationDetail tab={AUTHENTICATION}/>}/>
            <Route path={ROUTES_INTEGRATIONS}
                   element={<IntegrationSetup/>}/>
            <Route path={ROUTES_MY_ACCOUNT}
                   element={<MyAccount/>}/>
            <Route path={ROUTES_RESET_PASSWORD}
                   element={<ResetPassword/>}/>
        </Routes>
    </>;
}


function App() {
    return (
        <Provider store={configureStore()}>
            <Router>
                <SnackbarProvider>
                    <div className={"persistent-left-background"}></div>
                    <div className={"left-background"}></div>
                    <Main/>
                    <Popup/>
                    <Notifier/>
                </SnackbarProvider>
            </Router>
        </Provider>
    );
}

export default App;
