import * as React from "react"
import { useEffect } from "react";
import { useState } from "react";
import SubmitButton from "../elements/SubmitButton.js";
import TextInput from "../elements/TextInput.js";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../elements/Dropdown.js";
import { isNullOrUndefinedOrEmpty, formatDate, uniqueById } from "../../utils.js";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createMetrcAccount, deleteMetrcAccount, updateMetrcAccount } from "../../redux/models/integrations/Action.js";
import { fetchActiveStates } from "../../redux/models/states/Action.js";
import { enqueueSnackbar } from '../../redux/Actions.js';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment.js';
import IconButton from '@mui/material/IconButton/IconButton.js';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { selectOrganization } from "../../redux/models/organizations/Action.js";
import { updateAccountStatus } from "../../redux/user/Action.js";
import Loading from "../elements/Loading.js";
import { ADMIN_ROLE, SUPERADMIN_ROLE } from "../../utils/Constants.js";
import { kickoffBackfills } from "../../redux/models/integrations/Action.js";
import dayjs from "dayjs";


const MetrcAccount = ({
    create = false,
    enableEdit = false,
    enableDelete = false,
    onCreate = (newAccount) => { },
    onUpdate = (updatedAccount) => { },
    onDelete = (accountId) => { },
    ...props
}) => {
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);
    const accountStatuses = useSelector(state => state.userReducer.loggedInUser.accountStatuses);
    const accountStatus = accountStatuses.find(accountStatus => {
        if (isNullOrUndefinedOrEmpty(selectedOrganization)) {
            return !isNullOrUndefinedOrEmpty(organizationUsers.find(ou => ou.organization_id === accountStatus.organization_id && (ou.role === ADMIN_ROLE || ou.role === SUPERADMIN_ROLE)));
        }
        return accountStatus.organization_id === selectedOrganization.id
    });
    const today = new Date();
    const lastYear = (new Date()).setMonth(today.getMonth() - 12);
    const endDate = dayjs(today);
    const startDate = dayjs(lastYear);
    const [savingAccount, setSavingAccount] = useState(false);
    const [kickingOffBackfill, setKickingOffBackfill] = useState(false);

    const [showPasswords, setShowPasswords] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [states, setStates] = useState([])
    const [formState, setFormState] = useState({});
    const [errors, setErrors] = useState({
        display_name: "",
        user_key: "",
        state_id: "",
        frontend_username: "",
        frontend_password: "",
        organization_id: ""
    });

    useEffect(() => {
        const abortController = new AbortController();
        if (isNullOrUndefinedOrEmpty(states)) {
            dispatch(fetchActiveStates(abortController)).then((states) => {
                setStates(uniqueById(states));
            }).catch(e => console.error(e));
        }
        return () => {
            abortController.abort();
        }
    }, [])

    if (organizations.length === 0) {
        return <h1>You must add an organization before you can add a Metrc integration.</h1>
    }

    if (isNullOrUndefinedOrEmpty(formState.organization_id)) {
        setFormState({ ...formState, organization_id: organizations[0].id });
    }

    const _selectOrganization = (organizationId) => {
        const organization = organizations.find((org) => parseInt(org.id) === parseInt(organizationId));
        dispatch(selectOrganization(organization));
    }

    const del = enableDelete ? <div className="form-row submit">
        <SubmitButton onClick={(event) => {
            event.preventDefault();
            dispatch(deleteMetrcAccount(formState.id || props.id)).then((response) => {
                onDelete(props.id)
                navigate("/integrations/metrc/list")
            }).catch((errs) => {
                setErrors({ ...errors, ...errs });
            });
        }}>Delete</SubmitButton>
    </div> : "";

    const edit = enableEdit ? <div className="form-row submit">
        <SubmitButton onClick={(event) => {
            event.preventDefault();
            dispatch(updateMetrcAccount({ ...props, ...formState })).then((updatedMetrcAccount) => {
                onUpdate(updatedMetrcAccount)
            }).catch((errs) => {
                setErrors({ ...errors, ...errs });
            });
        }}>Save</SubmitButton>
    </div> : "";

    if (isNullOrUndefinedOrEmpty(states)) {
        return <Loading />
    }

    const stateOptions = [
        { "label": "Choose a state", value: "" },
        ...states.map((state) => {
            return { "label": state.name, "value": state.id };
        })
    ];

    const passwordType = showPasswords ? "text" : "password";

    if (create) {
        return <>
            <div className="form-row">
                <TextInput
                    name="display_name"
                    autoComplete={"off"}
                    label="Display Name"
                    display_name={formState.display_name || props.display_name}
                    formState={formState}
                    errors={errors}
                    setFormState={setFormState}
                />
            </div>
            <div className="form-row">
                <Dropdown
                    id={"state_id"}
                    label={"State"}
                    value={formState.state_id || props.state_id}
                    options={stateOptions}
                    onChange={(value) => {
                        setFormState({ ...formState, state_id: value });
                    }}
                    errors={errors}
                />
            </div>
            <div className="form-row">
                <TextInput
                    name="user_key"
                    label="User Key"
                    autoComplete={"off"}
                    formState={formState}
                    user_key={formState.user_key || props.user_key}
                    errors={errors}
                    setFormState={setFormState}
                    type={passwordType}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton onClick={() => { setShowPasswords(!showPasswords) }}>
                                {showPasswords ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }}
                />
            </div>
            <div className="form-row">
                <TextInput
                    name="frontend_username"
                    label="Frontend Username (optional)"
                    formState={formState}
                    autoComplete={"off"}
                    errors={errors}
                    frontend_username={formState.frontend_username || props.frontend_username}
                    setFormState={setFormState} />
                <TextInput
                    name="frontend_password"
                    label="Frontend Password (optional)"
                    formState={formState}
                    autoComplete={"off"}
                    type={passwordType}
                    frontend_password={formState.frontend_password || props.frontend_password}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton onClick={() => { setShowPasswords(!showPasswords) }}>
                                {showPasswords ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }}
                    errors={errors}
                    setFormState={setFormState} />
            </div>
            <div className="form-row">
                <Dropdown
                    id={"organization_id"}
                    label={"Which organization is this for?"}
                    onChange={_selectOrganization}
                    errors={errors}
                    value={selectedOrganization.id}
                    options={
                        organizations.map((organization) => {
                            return {
                                label: organization.name,
                                value: organization.id
                            }
                        })
                    } />
            </div>
            <div className="form-row submit">
                {savingAccount ? <span>Saving your account...</span> : ""}
                {kickingOffBackfill ? <span>Kicking off a backfill of historic data...</span> : ""}
                <SubmitButton onClick={(click) => {
                    click.preventDefault();
                    setErrors({
                        display_name: "",
                        user_key: "",
                        state_id: "",
                        frontend_username: "",
                        frontend_password: "",
                        organization_id: ""
                    });
                    setSavingAccount(true);
                    dispatch(createMetrcAccount({ ...props, ...formState, organization_id: selectedOrganization.id })).then((metrcAccount) => {
                        setSavingAccount(false);
                        onCreate(metrcAccount);
                        dispatch(updateAccountStatus({ ...accountStatus, has_integration: true }))
                        setKickingOffBackfill(true);
                        dispatch(kickoffBackfills({
                            metrc_account_id: metrcAccount.id,
                            start_date: formatDate(startDate),
                            end_date: formatDate(endDate),
                            updated_at: new Date().toISOString()
                        })).then(response => {
                            setKickingOffBackfill(false);
                        });
                        setErrors({
                            display_name: "",
                            user_key: "",
                            state_id: "",
                            frontend_username: "",
                            frontend_password: "",
                            organization_id: ""
                        })
                        setFormState({});
                    }).catch((errs) => {
                        setSavingAccount(false);
                        setKickingOffBackfill(false);
                        if (!isNullOrUndefinedOrEmpty(errs)) {
                            if (errs.status === 401 || errs.status === 403) {
                                dispatch(enqueueSnackbar({
                                    message: "You don't have permissions to create metrc accounts for this organization. Are you admin?",
                                    options: {
                                        variant: 'error',
                                    },
                                }));
                            } else {
                                dispatch(enqueueSnackbar({
                                    message: errs.error,
                                    options: {
                                        variant: 'error',
                                    },
                                }));
                            }
                        }
                        setErrors({ ...errors, ...errs });
                    });
                }}>Submit</SubmitButton>
            </div>
        </>
    } else {
        return <>
            <h2>{props.display_name}</h2>
            <div className="form-row">
                <TextInput
                    name="display_name"
                    label="Display Name"
                    formState={formState}
                    errors={errors}
                    disabled={!enableEdit}
                    setFormState={setFormState}
                    display_name={formState.display_name || props.display_name}
                />
            </div>
            <div className="form-row">&nbsp;</div>
            <div className="form-row">
                <Dropdown
                    id={"organization_id"}
                    label={"Which organization is this for?"}
                    onChange={_selectOrganization}
                    errors={errors}
                    value={selectedOrganization.id}
                    options={
                        organizations.map((organization) => {
                            return {
                                label: organization.name,
                                value: organization.id
                            }
                        })
                    } />
            </div>
            <div className="form-row">&nbsp;</div>
            <div className="form-row">
                <Dropdown
                    id={"state_id"}
                    label={"State"}
                    value={props.state_id || formState.state_id}
                    options={stateOptions}
                    onChange={(value) => {
                        setFormState({ ...formState, state_id: value });
                    }}
                    errors={errors}
                />
            </div>
            <div className="form-row">
                <TextInput
                    name="user_key"
                    label="User Key"
                    formState={formState}
                    setFormState={setFormState}
                    errors={errors}
                    disabled={!enableEdit}
                    type={passwordType}
                    user_key={formState.user_key || props.user_key}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton onClick={() => { setShowPasswords(!showPasswords) }}>
                                {showPasswords ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }}
                />
            </div>
            <div className="form-row">
                <TextInput
                    name="frontend_username"
                    label="Frontend Username"
                    formState={formState}
                    errors={errors}
                    frontend_username={formState.frontend_username || props.frontend_username}
                    setFormState={setFormState}
                    disabled={!enableEdit} />
                <TextInput
                    name="frontend_password"
                    label="Frontend Password"
                    formState={formState}
                    errors={errors}
                    setFormState={setFormState}
                    frontend_password={formState.frontend_password || props.frontend_password}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton onClick={() => { setShowPasswords(!showPasswords) }}>
                                {showPasswords ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }}
                    type={passwordType}
                    disabled={!enableEdit} />
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} className="actions">{enableDelete ? del : ""}{edit}</Grid>
            </Grid>
        </>
    }
}

export default MetrcAccount;