import React, { useState } from 'react';
import { acceptTermsOfService, setUserProfile } from "../redux/user/Action.js";
import { useDispatch } from 'react-redux';
import "./styles/TermsOfService.css";
import SubmitButton from "../components/elements/SubmitButton.js";
import { isNullOrUndefinedOrEmpty } from "../utils.js";
import Loading from '../components/elements/Loading.js';

const TermsOfService = ({ loggedInUserProfile }) => {
    const dispatch = useDispatch();

    const [agreesToTerms, setAgreesToTerms] = useState(loggedInUserProfile && loggedInUserProfile.has_accepted_tos);

    const checkboxHandler = () => {
        setAgreesToTerms(!agreesToTerms);
    }

    if (isNullOrUndefinedOrEmpty(loggedInUserProfile)) {
        return <Loading />
    }

    return (
        <div className={"terms-of-service"}>
            <h1 id="terms-of-service-title">APPLICATION TERMS OF SERVICE</h1>
            <form className="form">
                <div id='terms-of-service' className="bg-light">
                    <h4>Last Changes to Application Terms of Service: 07/19/2023 </h4><br/>
                    Please read these terms and conditions of service (“Terms of Service”) carefully before accessing or using this website. These Terms of Service constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you” or “Consumer”) and Canold, LLC (“Canold” or “Canold” or “we” or “us” or “our”), governing your access to and use of the SSO website as well as any other media form, media channel, mobile application or mobile website related or connected thereto (collectively, the “Site”), and the web application (“Application”) or services supplied by or on behalf of us, including our text services or services provided through the Site (collectively, “Services”). Supplemental terms and conditions or documents that may be posted on the Application from time to time, and any new features or tools that are added to the Site shall also be subject to and are hereby expressly incorporated into these Terms of Service by reference. <br/>
                    <br/>
                    By accessing, browsing, or otherwise using the Application, you agree to be bound and to abide by the terms and conditions set forth below. If you do not agree to be bound by all of the terms and conditions contained herein, you may not access or use the Site or the Services.  If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.<br/>
                    You can review the most current version of the Terms of Service at any time on this page.  We reserve the right to update, modify, or replace any part of these Terms of Service in our sole discretion by giving you notice and asking you to accept the new Terms  By accessing this Application or using our Services following any such change, you agree to adhere to and be bound by the Terms of Service as modified.<br/>
                    Information provided on the Application is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Persons who choose to access the Application from such locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.<br/>
                    <br/>
                    <h4>Definitions</h4><br/>
                    “Applicable Law” means any and all United States or foreign applicable laws, statutes, regulations or rules promulgated by governing authorities having jurisdiction over the Parties, the Wholesaler Products, the Application, Site or Service, excluding only United States federal laws applicable to cannabis.<br/>
                    “Content” means any and all information, personal information, data, results, ideas, plans, sketches, texts, files, links, images, photos, video, sound, inventions (whether or not patentable), notes, works of authorship, articles, feedback, or other materials.<br/>
                    “Consumer Data” means all data, including personally identifiable information of Consumer or its retail customers, submitted by a Consumer.<br/>
                    “Consumer Content” means any Content provided, imported or uploaded to, or otherwise used by Consumer, Consumer Accounts or on Consumer's behalf with the Services or Application.<br/>
                    “Parties” means Consumer or Canold, as applicable.<br/>
                    “Public Content” means Content available for free and without registration on the Site.<br/>
                    “Service(s)” means, collectively, the services provided by Canold through the Application.<br/>
                    “Site” means, collectively, the Canold website.<br/>
                    “Subscription” means Wholesaler's authorized use, pursuant to these Terms of Service, of the Application, Site and Services during the Term.<br/>
                    “Wholesaler” means any seller of products through the Application, Site or Services.<br/>
                    “Wholesaler Products” means any Wholesaler-branded products, which may include cannabis product, hemp-derived products and other products.<br/>
                    “Wholesaler Store” means an online e-commerce page operating through the Application.<br/>
                    <br/>
                    <h4>License and Restrictions.</h4><br/>
                    <h5>License</h5>  
                    Subject to the terms and conditions contained in these Terms of Service, Canold hereby grants to Consumer a non-exclusive, non-transferable, right and license to access the features and functions of the Services during the Term, solely through the Site, for their intended purposes, and solely as contemplated in these Terms of Service. <br/>
                    <h5>Purchasing</h5> 
                    Consumer may place orders for Wholesale Products (each, an “Order”) through one or more Wholesaler Store. Consumer shall be responsible for paying each applicable Wholesaler in full for Wholesaler Products in each Order no later than 30 days from receipt of an Invoice (as defined below). Consumer shall not pay Canold for such Wholesaler Products. Consumer understands and acknowledges that Wholesaler or its licensed partners (the “Fulfillment Party”) will arrange for the delivery of the Wholesaler Products in each Order to the Consumer. Canold collects no payment from Consumer, and Canold shall have no responsibility or liability to Consumer for delivery of or quality of Wholesaler Products. Consumer understands that by placing any Order, a Consumer agrees to be bound by the terms of this Agreement and commits to pay for the Wholesaler Products in the Order in accordance with these Terms of Service.<br/>
                    <h5>Invoicing</h5>  
                    All Consumers shall be provided with invoices generated through the Application (each, an “Invoice”). Wholesalers are solely responsible for confirming the accuracy of any Invoices provided through the Application. Canold shall have no responsibility for the accuracy of Invoices.<br/>
                    <h5>Accounts</h5> 
                    Canold will create an Organization Account (“Organization Account”) for Consumer and Individual Accounts (“Individual Accounts” and together with Organizational Accounts, “Accounts”) for Consumer's employees or agents.  Consumer is responsible for any action taken under a Consumer Account, whether legitimately or illegitimately accessed, and all Consumer Content posted using a Consumer Account. Consumer is responsible for protecting the confidentiality of any non-public authentication credentials (including passwords) and the confidentiality of Consumer Content.  Allowing multiple people to access the Services with the same Account is prohibited. No Account holder may share holder's password with any other users.  Each Consumer user must register for a new Account. Consumer is responsible for ensuring that any individual accessing an Account assigned to Consumer complies with the restrictions on Consumer set forth herein. Consumer shall be liable for any actions taken by any individual using a Consumer Account that violate the terms of these Terms of Service.<br/>
                    <h5>Usage Restrictions</h5> 
                    Consumer shall not: (a) modify, translate, or create derivative works of the Application, Site or Service; (b) reproduce, copy, distribute, display, perform, sell, offer for sale, make or import the Site or the Services in whole or in part. Consumer shall not (i) copy or duplicate the Application, Site or Service; (ii) decompile, disassemble, reverse engineer or otherwise attempt to obtain or perceive the source code from which any component of the Site or Service is compiled or interpreted, and  Consumer acknowledges that nothing in these Terms of Service will be construed to grant  Consumer any right to obtain or use such source code; or (iii) except as expressly permitted in these Terms of Service, assign, sublicense, sell, resell, lease, rent or otherwise transfer or convey, or pledge as security or otherwise encumber, Consumer's rights under the licenses granted herein. Consumer will ensure that its use of the Site and Service complies with all applicable laws, statutes, regulations or rules promulgated by governing authorities having jurisdiction over the Parties, the Site or Service. <br/>
                    <h5>Retained Rights; Ownership</h5> 
                    Notwithstanding anything to the contrary herein, as between Canold and Consumer, each Party retains all right, title and interest in their respective sites, service and brand, including all copyrights, patents, trade secrets, trademarks, trade names and other intellectual property therein.  All goodwill arising in or from  each Party's respective brand shall inure solely to such Party's benefit.  Except to the extent expressly granted herein, neither Party acquires any rights in any of the foregoing. As between Canold and Consumer, Canold shall (i) retain all rights in all intellectual property contained in or provided through the Application, Site or Services, including without limitation any and all improvements, modifications, enhancements, updates, additions, and new versions of the Application, Site or Services, whether requested, created or developed by Canold or Consumer (collectively, “Canold IP”).<br/>
                    <h5>Mutual Restriction on Authority</h5>  
                    Neither Party has authority to accept, or hold itself out as having the authority to accept, any obligation or liability on behalf of, or in any way bind, the other party in these Terms of Service to third parties.  Consumer shall make no representation or warranty regarding the Application, Site or Service.<br/>
                    <h5>Independent Contractor</h5>
                    The Parties are independent contractors, and nothing contained in these Terms of Service shall be construed as creating any agency, partnership, or other form of joint enterprise between the Parties.  Each Party is solely responsible for all of its employees and agents and its labor costs and expenses arising in connection therewith, and is responsible for, and will indemnify the other Party from, any and all claims, liabilities, damages, debts, settlements, costs, attorneys' fees, expenses and liabilities of any type whatsoever that may arise on account of the either Party's activities or those of its employees or agents.<br/>
                    <h5>Representations and Warranties; Privacy; Disclaimer</h5>
                    Each Party hereby represents and warrants (i) that it is duly organized, validly existing and in good standing under the laws of its jurisdiction of incorporation; (ii) that the execution and performance of these Terms of Service will not conflict with or violate any provision of any law having applicability to such Party; and (iii) that these Terms of Service, when executed and delivered, will constitute a valid and binding obligation of such Party and will be enforceable against such Party in accordance with its terms.<br/>
                    Consumer represents and warrants that (a) it shall only purchase Wholesaler Products through the Application, Site or Service that are in strict compliance with Applicable Laws; (b) it shall at all times retain its license(s) (as required by Applicable Laws) in good standing and in compliance with Applicable Laws; (c) it shall notify Canold within twenty-four (24) hours of receipt of any notice of any regulatory violation received from any applicable local licensing authority; (e) neither Consumer nor any of its owners, directors, officers, members, partners, shareholders, affiliates, employees, insurers, successors and assigns (collectively, the “Consumer Parties”)  is named, either directly or by an alias, pseudonym or nickname, on the lists of “Specially Designated Nationals” or “Blocked Persons” maintained by the U S Treasury Department's Office of Foreign Assets Control, and (g) Consumer will not, and it will cause each Consumer Party not to, take any action that would constitute a violation of any Applicable Law against corrupt business practices, against money laundering and/or against facilitating or supporting persons or entities who conspire to commit acts of terror against any person or entity, including as prohibited by the US Patriot Act or any similar laws.<br/>
                    Consumer further represents and warrants (i) that it has the right to disclose and provide to Canold any data and Consumer Content provided through use and access of the Site and Service, (ii) that  Consumer's use of and access to the Site and Service complies with applicable laws and regulations, except federal laws relating to cannabis, (iii) that it is solely responsible for its own compliance with the General Data Protection Regulation (“GDPR”) and any other applicable data and privacy regulations in the state of California or the United States of America, and (iv) that it shall respond to privacy related requests made by individuals or by Canold at the request of an individual, or regulatory authority in a timely and compliant manner.<br/>
                    Consumer acknowledges that Canold does not give legal advice or training related to GDPR or other privacy regulations. Consumer agrees to hold Canold harmless and to indemnify Canold for any and all matters or disputes arising out of or related in any way to Consumer's compliance with the GDPR or other privacy regulation. Failure to comply with any privacy obligations, or to maintain the required level of compliance with the GDPR by Consumer may, at Canold's sole discretion, constitute a violation of these Terms of Service.<br/>
                    <h5>Consumer Data</h5> 
                    Canold may use Consumer Data in its discretion in accordance with Canold's privacy policy. For the avoidance of doubt, all rights to any Consumer Data collected through third party websites shall be owned by Canold and Canold shall use such Consumer Data in its sole discretion in accordance with Canold's privacy policy.<br/>
                    CANOLD MAKES NO REPRESENTATION THAT THE PRICING, PRODUCT INFORMATION, INVOICES, DISCOUNTS OR OTHER INFORMATION PROVIDED THROUGH THE APPLICATION WILL BE ACCURATE OR ERROR-FREE. CANOLD MAKES NO REPRESENTATION THAT THE PRODUCTS ORDERED WILL BE DELIVERED BY WHOLESALER AS EXPECTED, WILL MEET SPECIFICATIONS OR THAT ANY WILL MATCH THE  INFORMATION OR CERTIFICATES OF ANALYSIS PROVIDED BY WHOLESALER. EXCEPT AS EXPRESSLY SET FORTH HEREIN, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, CANOLD PROVIDES THE APPLICATION, SITE AND SERVICE AS-IS, AND EXPRESSLY DISCLAIMS ANY AND ALL OTHER PROMISES, REPRESENTATIONS AND WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SYSTEM INTEGRATION, DATA ACCURACY, TITLE AND/OR NON-INFRINGEMENT. CANOLD DOES NOT WARRANT THAT THE APPLICATION, SITE OR SERVICE WILL MEET CONSUMER'S REQUIREMENTS OR THAT THE OPERATION OF THE SITE OR SERVICE, OR ACCESS TO THE CONTENT DISPLAYED THROUGH THE SITE, SERVICES OR APPLICABTION WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT ERRORS WILL BE CORRECTED. CANOLD DOES NOT TAKE ANY RESPONSIBILITY FOR, OR ENDORSE OR RECOMMEND, ANY PUBLIC CONTENT PROVIDED BY WHOLESALER, CONSUMER OR ANY THIRD PARTY, THROUGH THE SERVICE.  CANOLD MAY REMOVE PUBLIC CONTENT FROM THE SITE AND SERVICE AT ANY TIME.<br/>
                    <h5>Limitation of Liability</h5>
                    IN NO EVENT WILL CANOLD BE LIABLE TO CONSUMER FOR ANY INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, REGARDLESS OF THE NATURE OF THE CLAIM, INCLUDING, WITHOUT LIMITATION, LOST PROFITS, COSTS OF DELAY, ANY FAILURE OF DELIVERY, BUSINESS INTERRUPTION, COSTS OF LOST OR DAMAGED DATA, LOSS OF GOODWILL, OR LIABILITIES TO THIRD PARTIES, INCLUDING INDIVIDUAL ACCOUNT HOLDERS, ARISING FROM ANY SOURCE, EVEN IF CANOLD HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  THIS LIMITATION UPON DAMAGES AND CLAIMS IS INTENDED TO APPLY WITHOUT REGARD TO WHETHER OTHER PROVISIONS OF THESE TERMS HAVE BEEN BREACHED OR HAVE PROVEN INEFFECTIVE. <br/>
                    EXCEPT FOR CANOLD'S INDEMNIFICATION OBLIGATIONS HEREUNDER, THE CUMULATIVE LIABILITY OF CANOLD TO CONSUMER FOR ALL CLAIMS ARISING FROM OR RELATING TO THESE TERMS, INCLUDING, WITHOUT LIMITATION, ANY CAUSE OF ACTION SOUNDING IN CONTRACT, TORT, OR STRICT LIABILITY, WILL NOT EXCEED THE TOTAL AMOUNT OF ALL FEES PAID TO ANY WHOLESALER BY CONSUMER FOR ORDERS PLACED DURING THE SIXTY (60) DAY PERIOD PRIOR TO THE ACT, OMISSION OR EVENT GIVING RISE TO SUCH LIABILITY.  THIS LIMITATION OF LIABILITY IS INTENDED TO APPLY WITHOUT REGARD TO WHETHER OTHER PROVISIONS OF THESE TERMS HAVE BEEN BREACHED OR HAVE PROVEN INEFFECTIVE.<br/>
                    THE PARTIES AGREE THAT THIS SECTION 5 FAIRLY ALLOCATES THE RISKS IN THESE TERMS BETWEEN THE PARTIES.  THE PARTIES FURTHER AGREE THAT THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES AND THAT THE LIMITATIONS SPECIFIED IN THIS SECTION 5 SHALL APPLY NOTWITHSTANDING ANY FAILURE OF THE ESSENTIAL PURPOSE OF THESE TERMS OR ANY LIMITED REMEDY HEREUNDER.<br/>
                    <h4>Term and Termination</h4>
                    <h5>Term</h5> 
                    The term of these Terms of Service shall continue unless terminated in accordance with its terms (“Term”). <br/>
                    <h5>Termination</h5>  
                    Canold in its sole discretion may terminate these Terms of Service and all Consumer Accounts promptly upon written notice to Consumer.<br/>
                    <h5>Termination Upon Bankruptcy or Insolvency</h5>  
                    Either Party may, at its option, terminate these Terms of Service immediately upon written notice to  the other Party in the event that either Party (i) becomes insolvent or unable to pay its debts when due; (ii) files a petition in bankruptcy, reorganization or similar proceeding, or, if filed against, such petition is not removed within ninety (90) days after such filing; (iii) discontinues it business; or in the event that (iv) a receiver is appointed or there is an assignment for the benefit of  such Party's creditors.<br/>
                    <h5>Effect of Termination</h5>  
                    Upon any termination or expiration of these Terms of Service, (i)  Canold shall have the right to immediately terminate all access to, and Consumer shall immediately discontinue use of, the Application, Site and Service, Canold Confidential Information and Canold IP; (ii)  all accrued and outstanding amounts due under these Terms of Service shall be paid to the applicable Wholesaler; (iii) each Party shall delete any of the other Party's Confidential Information from computer storage or any other media including, but not limited to, online and off-line libraries; and (iv) each Party shall return to the other Party or, at the other Party's option, destroy, all physical copies of any the other Party's Confidential Information.<br/>
                    Survival. Article 1, Section 2.9, Article 8, Article 9, Article 10 shall survive any termination or expiration of these Terms of Service for any reason.<br/>
                    Indemnification.<br/>
                    <h5>Canold Indemnification Obligations</h5>  
                    Canold agrees to indemnify, defend and hold harmless Consumer from and against any and all losses, liabilities, costs (including reasonable attorneys' fees) or damages resulting from any claim by any third party that the Site or Service infringes such third Party's U.S. patents issued as of the Effective Date, or infringes or misappropriates such third party's copyrights or trade secret rights under applicable laws of any jurisdiction within the United States of America, provided that Consumer promptly notifies Canold in writing of the claim, cooperates with Canold, and allows Canold sole authority to control the defense and settlement of such claim.  If such a claim is made or appears possible, Consumer agrees to permit Canold, at Canold's sole discretion, to enable it to continue to use the Site or Service, as applicable, or to modify or replace any such infringing material to make it non-infringing.  If Canold determines that none of these alternatives is reasonably available, Consumer shall, upon written request from Canold, cease use of, and, if applicable, return, such materials as are the subject of the infringement claim.  This Section 7.1 shall not apply if the alleged infringement arises, in whole or in part, from (i) modification of the Application, Site or Service by anyone other than Canold, (ii) combination, operation or use of the Application, Site or Service with other software, hardware or technology without Canold's written approval (any of the foregoing circumstances, a “Consumer Indemnity Responsibility”).<br/>
                    <h5>Consumer Indemnification Obligations</h5>   
                    Consumer agrees to indemnify, defend and hold harmless Canold from and against any and all losses, liabilities, costs (including reasonable attorneys' fees) or damages resulting from any claim by any third party arising in connection with (i) Consumer's negligence or willful misconduct; (ii) a breach by  Consumer of these Terms of Service, including any failure to pay amounts due to a Wholesaler or other Wholesaler claim involving Consumer obligations; (iii) Consumer Content, Consumer Data, or any breach of data, privacy or other applicable regulations or laws by Consumer; or (iv) a Consumer Indemnity Responsibility, provided that Consumer will not settle any third-party claim against Canold unless such settlement completely and forever releases Canold from all liability with respect to such claim or unless Canold consents to such settlement, and further provided that Canold will have the right, at its option, to defend itself against any such claim or to participate in the defense thereof by counsel of its own choice.<br/>
                    <h5>Confidential Information</h5>
                    “Confidential Information” means all information and materials that are disclosed by a Party (“Disclosing Party”) to the other Party (“Receiving Party”) pursuant to these Terms of Service: (i) in written form or otherwise, or (ii) where, due to its character or nature, a reasonable person in a like position and under like circumstances would treat the information as confidential.  Confidential Information of Canold includes, but is not limited to, any of Canold product specifications or technical information not generally released.<br/>
                    <h5>No Disclosure</h5>  
                    Each Party, as Receiving Party, agrees to maintain all such Confidential Information received from the Disclosing Party, both orally and in writing, in confidence and agrees not to disclose or otherwise make available such Confidential Information to any third party without the prior written consent of the Disclosing Party.  The Receiving Party further agrees to use the Confidential Information only for the purpose of fulfilling its duties and obligations and exercising its rights under these Terms of Service, and to restrict access to the Confidential Information to such of its personnel, agents, and/or consultants, if any, who have a need to have access for purposes of performing such Party's obligations hereunder and who have been advised of and have agreed in writing to treat such information in accordance with the terms of these Terms of Service.  Whenever requested by the Disclosing Party, the Receiving Party shall immediately return to the Disclosing Party all manifestations of the Confidential Information or, at the Disclosing Party's option, shall destroy all such Confidential Information as the Disclosing Party may designate. The Receiving Party shall provide the Disclosing Party written certification of the Receiving Party's compliance with the Receiving Party's obligations under this Section.  The Receiving Party's obligation of confidentiality shall survive these Terms of Service for a period of three (3) years from the date of its termination, and thereafter shall terminate and be of no further force or effect.<br/>
                    <h5>Exclusions</h5>  
                    The Receiving Party's obligations under Section 9.2 above shall not apply to Confidential Information which as proven by written records: (i) is or becomes a matter of public knowledge through no fault of or action by the Receiving Party; (ii) was rightfully in the Receiving Party's possession prior to disclosure by the Disclosing Party; (iii) subsequent to disclosure, is rightfully obtained by the Receiving Party from a third party who is lawfully in possession of such Confidential Information without restriction; or (iv) is independently developed by the Receiving Party without reference to the Disclosing Party's information.  If disclosure of Confidential Information is required by law or judicial order, such disclosure shall not constitute a breach of this Section, provided that prior written notice of such required disclosure is furnished to the Disclosing Party as soon as practicable, and prior to disclosure occurring, in order to afford the Disclosing Party an opportunity to seek a protective order or other legal remedy to prevent such disclosure, and that if such order or remedy cannot be obtained, disclosure may be made without liability.<br/>
                    <h5>Notices</h5>
                    All notices, requests, demands or communications required or permitted hereunder shall be in writing, delivered personally or by electronic mail, certified or registered mail or overnight courier at the respective addresses set forth above (or at such other addresses as shall be given in writing by either Party to the other). All notices and other communications shall be deemed effective upon receipt or three (3) business days from the date sent if sent by registered or certified mail.<br/>
                    <h4>Applicable Law and Conflict Resolution</h4>
                    <h5>Applicable Law</h5>  
                    The laws of the Commonwealth of Massachusetts shall govern these Terms of Service, without regard to its conflicts of laws provisions.<br/>
                    <h5>Arbitration</h5>  
                    Any dispute arising out of these Terms of Service shall be resolved by binding arbitration under the Comprehensive Arbitration Rules & Procedures (“Rules”) of Judicial Arbitration and Mediation Services (hereinafter “JAMS”).  A single arbitrator shall be selected according to the JAMS Rules within thirty (30) days of submission of the dispute to JAMS.  The arbitrator shall have the power to enter any award that could be entered by a Judge of the state court of the Commonwealth of Massachusetts sitting without a jury, and only such power, except that the arbitrator shall not have the power to award punitive damages, treble damages, or any other damages which are not compensatory, even if permitted under the laws of the Commonwealth of Massachusetts or any other applicable law. The prevailing Party shall be entitled to reimbursement of all legal costs and fees from the non-prevailing Party; provided that the Arbitrator shall determine allocations for such purposes in the event of a split determination.  The arbitration award may be enforced in any court having jurisdiction over the parties and the subject matter of the arbitration.  Notwithstanding the foregoing, the parties (a) agree that Canold shall have the right to seek a temporary or permanent injunction (or other equitable relief), and file the necessary underlying lawsuit, in any court having jurisdiction over the parties anywhere in the world as necessary to protect the Canold name, proprietary information or other proprietary rights, and (b) irrevocably submit to the non-exclusive jurisdiction of the State and Federal Courts in Boston in the Commonwealth of Massachusetts, United States, in any action to enforce an arbitration award.<br/>
                    <h5>Injunctive Relief</h5>  
                    Notwithstanding Section 10.2 above, with respect to any breach of Section 8 (“Confidential Information”) or a violation of Canold's proprietary rights, either Party may seek equitable relief in any court of competent jurisdiction.<br/>
                    <h5>General Provisions</h5>  
                    Neither Party may assign, voluntarily, by operation of law, or otherwise, any rights or delegate any duties under these Terms of Service without the prior written consent of the other Party, except in connection with a merger, reorganization or sale of all or substantially all assets or equity interests.  Subject to the foregoing, these Terms of Service shall be binding upon and shall inure to the benefit of the respective successors and assigns of the Parties hereto.  Except for the obligation to pay monies due, neither Party will be liable for performance delays or for non-performance due to causes beyond its reasonable control.  Any failure by either Party to enforce any provision of these Terms of Service at any time shall not be deemed to be a waiver of such Party's right hereunder, nor shall it prejudice such Party's right to take subsequent action.  Should any provision of these Terms of Service be or become invalid or unenforceable in whole or in part, the remaining provisions shall be valid and enforceable.  The paragraph headings and captions of these Terms of Service are included merely for convenience of reference.  They are not to be considered part of, or to be used in interpreting these Terms of Service and in no way limit or affect any of the contents of these Terms of Service or its provisions.  These Terms of Service may be executed in two or more counterparts, each of which shall be deemed an original, but all of which together shall constitute one and the same instrument.  These Terms of Service represents the entire agreement of the Parties hereto and replaces all other prior or contemporaneous communications between the Parties, whether oral or written.  These Terms of Service shall not be amended or modified except by a writing signed by properly authorized representatives of both Parties.<br/>

                </div>
                <div>
                    <input type="checkbox" id="agreeToTerms" onChange={checkboxHandler} />
                    <label className='ml-2' htmlFor="agreeToTerms">I have read and agree to the <b>terms and conditions</b></label>
                </div>
                <SubmitButton disabled={!agreesToTerms} onSubmit={(event) => {
                    event.preventDefault();
                    dispatch(acceptTermsOfService(loggedInUserProfile)).then(() => {
                        dispatch(setUserProfile({ ...loggedInUserProfile, has_accepted_tos: true }));
                    });
                }}>Continue</SubmitButton>
            </form>
        </div>
    );
};

export default TermsOfService;
