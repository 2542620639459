import { SET_METRC_ACCOUNTS, SET_METRC_BACKFILLS } from "../../constants.js";
import { destroyFromDal, enqueueSnackbar, getFromApi, getFromDal, patchToDal, postToApi, postToDal } from "../../Actions.js";

export const setMetrcAccounts = (integrations) => {
    return dispatch => {
        return dispatch({
            type: SET_METRC_ACCOUNTS,
            accounts: integrations
        });
    }
}
export const createMetrcAccount = (metrcAccount) => {
    return dispatch => dispatch(postToDal("/metrc-accounts/", metrcAccount, "Metrc account created!"));
};
export const deleteMetrcAccount = (pk) => {
    return dispatch => dispatch(destroyFromDal("/metrc-accounts/", pk));
};
export const fetchMetrcAccounts = (params = {}, abortController = undefined) => {
    return dispatch => dispatch(getFromDal("/metrc-accounts/", params, abortController)).then((response) => {
        return response;
    });
};

export const fetchMetrcBackfills = (params, abortController) => {
    return dispatch => dispatch(getFromApi("/metrc-backfills/", params, abortController)).then((response) => {
        return response;
    });
}
export const kickoffBackfills = (payload, abortController) => {
    return dispatch => dispatch(postToApi("/metrc-backfills/", payload, "Backfill started! Check back here to keep up with the progress.", abortController));
}

export const setMetrcBackfills = (backfills) => {
    return dispatch => {
        return dispatch({
            type: SET_METRC_BACKFILLS,
            backfills: backfills
        })
    }
}

export const updateMetrcAccount = (partial) => {
    return dispatch => {
        const metrcAccountId = partial.id;
        return dispatch(patchToDal(`/metrc-accounts/${metrcAccountId}/`, partial, 'Metrc account updated.')).catch(err => {
            dispatch(enqueueSnackbar({
                message: "Could not update metrc account.",
                options: {
                    variant: "error",
                },
            }));
            return Promise.reject(err);
        });
    }
}
export const createLeaflogixAccount = (leaflogixAccount) => {
    return dispatch => dispatch(postToDal("/leaflogix-accounts/", leaflogixAccount));
};
export const deleteLeaflogixAccount = (pk) => {
    return dispatch => dispatch(destroyFromDal("/leaflogix-accounts/", pk));
};
export const fetchLeaflogixAccounts = (params) => {
    return dispatch => dispatch(getFromDal("/leaflogix-accounts/", params)).then((response) => {
        return response;
    });
};

export const fetchAccountStatuses = (abortController) => {
    return dispatch => dispatch(getFromApi("/users/setup-steps/", {}, abortController)).then(response => {
        return response;
    }).catch(err => {
        console.error(err);
        return [];
    });
}