
export const SUPERADMIN_ROLE = 'superadmin';
export const ADMIN_ROLE = 'admin';
export const MEMBER_ROLE = 'member';
export const INVITED_ROLE = 'invited';

export const ROUTES_FORGOT_PASSWORD = "/forgot-password";
export const ROUTES_ADMIN = "/admin/";
export const ROUTES_ORGANIZATIONS_LIST = "/organizations/list";

export const MANAGER_GROUP_ID = 1;
export const SALES_REPRESENTATIVE_GROUP_ID = 2;
export const FULFILLMENT_ASSOCIATE_GROUP_ID = 3;
export const PRODUCT_MASTER_EDITOR_GROUP_ID = 4;
export const FINANCE_GROUP_ID = 5;

export const LOGO = "logo";
export const PROFILE = "profile";
export const BANK = "accounts";
export const ROLES = "roles";
export const ROUTES_ORGANIZATIONS_DETAIL = "/organizations/:id/detail";
export const ROUTES_ORGANIZATIONS_DETAIL_LOGO = `/organizations/:id/detail/${LOGO}`;
export const ROUTES_ORGANIZATIONS_DETAIL_USERS_AND_ROLES = `/organizations/:id/detail/${ROLES}`;
export const ROUTES_ORGANIZATIONS_DETAIL_BANK_ACCOUNTS = `/organizations/:id/detail/${BANK}`;

export const BACKFILLS = "backfills";
export const AUTHENTICATION = "authentication";
export const ROUTES_INTEGRATIONS = "/integrations";
export const ROUTES_INTEGRATIONS_METRC_SETUP = "/integrations/metrc/setup";
export const ROUTES_INTEGRATIONS_METRC_LIST = "/integrations/metrc/list";
export const ROUTES_INTEGRATIONS_METRC_DETAIL = "/integrations/metrc/:id/detail";
export const ROUTES_INTEGRATIONS_METRC_DETAIL_BACKFILLS = `/integrations/metrc/:id/detail/${BACKFILLS}`;
export const ROUTES_INTEGRATIONS_METRC_DETAIL_AUTHENTICATION = `/integrations/metrc/:id/detail/${AUTHENTICATION}`;
export const ROUTES_MY_ACCOUNT = "/my-account/*";
export const ROUTES_RESET_PASSWORD = "/reset-password";
export const ROUTES_HOME = "/";

export const STATES = [ //todo these should be fetched from the database
    ["Alabama", "AL"],
    ["Alaska", "AK"],
    ["Arizona", "AZ"],
    ["Arkansas", "AR"],
    ["California", "CA"],
    ["Colorado", "CO"],
    ["Connecticut", "CT"],
    ["Delaware", "DE"],
    ["District of Columbia", "DC"],
    ["Florida", "FL"],
    ["Georgia", "GA"],
    ["Hawaii", "HI"],
    ["Idaho", "ID"],
    ["Illinois", "IL"],
    ["Indiana", "IN"],
    ["Iowa", "IA"],
    ["Kansas", "KS"],
    ["Kentucky", "KY"],
    ["Louisiana", "LA"],
    ["Maine", "ME"],
    ["Montana", "MT"],
    ["Nebraska", "NE"],
    ["Nevada", "NV"],
    ["New Hampshire", "NH"],
    ["New Jersey", "NJ"],
    ["New Mexico", "NM"],
    ["New York", "NY"],
    ["North Carolina", "NC"],
    ["North Dakota", "ND"],
    ["Ohio", "OH"],
    ["Oklahoma", "OK"],
    ["Oregon", "OR"],
    ["Maryland", "MD"],
    ["Massachusetts", "MA"],
    ["Michigan", "MI"],
    ["Minnesota", "MN"],
    ["Mississippi", "MS"],
    ["Missouri", "MO"],
    ["Pennsylvania", "PA"],
    ["Rhode Island", "RI"],
    ["South Carolina", "SC"],
    ["South Dakota", "SD"],
    ["Tennessee", "TN"],
    ["Texas", "TX"],
    ["Utah", "UT"],
    ["Vermont", "VT"],
    ["Virginia", "VA"],
    ["Washington", "WA"],
    ["West Virginia", "WV"],
    ["Wisconsin", "WI"],
    ["Wyoming", "WY"]
]

export const PENDING = "PENDING";
export const NOT_STARTED = "NOT_STARTED";
export const COMPLETE = "COMPLETE";

export const NO_LOGO = "No Logo"
export const CREATE = "create";
export const DESTROY = "destroy";
