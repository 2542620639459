import { Bar, Pie } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import { setMetrcBackfills, fetchMetrcBackfills, kickoffBackfills } from "../../../redux/models/integrations/Action.js";
import { useSearchParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ABORTED } from "../../../redux/constants.js";
import { isNullOrUndefinedOrEmpty, formatDate } from "../../../utils.js";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs/AdapterDayjs.js";
import { DatePicker } from "@mui/x-date-pickers/DatePicker/DatePicker.js";
import SubmitButton from "../../../components/elements/SubmitButton.js";
import Loading from "../../../components/elements/Loading.js";
import { DashboardValue } from "../../../components/reports/home/AggregateStats.js";
import Countdown from "../../../components/elements/Countdown.js";
import { Container, Grid, } from "@mui/material";
import ReactTable from "react-table-v6";


ChartJS.register(...registerables);


export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top"
        },
        title: {
            display: true,
            text: "Backfill Job Status by Month",
        },
    },
    scales: {
        y: {
            title: {
                text: "Number of Jobs",
                display: true
            }
        },
        x: {
            title: {
                text: "Month",
                display: true
            }
        }
    }
};

const PENDING = 0;
const ENQUEUED = 1;
const STARTED = 2;
const COMPLETE = 3;
const ERRORED = 4;

const statusNames = [PENDING, ENQUEUED, STARTED, COMPLETE, ERRORED];
const statusLabels = {
    [PENDING]: "Pending",
    [ENQUEUED]: "Enqueued",
    [STARTED]: "Started",
    [COMPLETE]: "Complete",
    [ERRORED]: "Errored",
}
const statusBackgroundColor = {
    [PENDING]: 'rgb(0, 0, 0, .5)',
    [ENQUEUED]: 'rgb(245, 152, 66, .5)',
    [STARTED]: 'rgb(245, 218, 66, .5)',
    [COMPLETE]: 'rgb(0, 245, 0, .5)',
    [ERRORED]: 'rgb(255, 0, 0, .5)',
}

const binByStatusAndMonth = (metrcBackfills, startDate, endDate) => {
    let bins = {}
    if (!isNullOrUndefinedOrEmpty(metrcBackfills)) {
        metrcBackfills.map(metrcBackfill => {
            let start = new Date(metrcBackfill.start_date)
            let status = metrcBackfill.status;
            let month = start.getMonth() + 1;
            let year = start.getFullYear();
            if (start < startDate || start > endDate) {
                return
            }
            if (isNullOrUndefinedOrEmpty(bins[year])) {
                bins[year] = {};
            }
            if (isNullOrUndefinedOrEmpty(bins[year][month])) {
                bins[year][month] = {};
            }
            if (isNullOrUndefinedOrEmpty(bins[year][month][status])) {
                bins[year][month][status] = 0;
            }
            bins[year][month][status] += 1;
        });
    }
    return bins;
}

const getTimeseries = (bins, startDate, endDate) => {
    if (isNullOrUndefinedOrEmpty(bins)) {
        return {};
    }
    const timeseries = { date: [], [PENDING]: [], [ENQUEUED]: [], [STARTED]: [], [COMPLETE]: [], [ERRORED]: [] };
    let start = new Date(startDate.$d);
    let end = new Date(endDate.$d);
    while (start < end) {
        const year = start.getFullYear();
        const month = start.getMonth() + 1;
        if (!isNullOrUndefinedOrEmpty(bins[year]) && !isNullOrUndefinedOrEmpty(bins[year][month])) {
            const statuses = bins[year][month];
            statusNames.map(statusName => {
                const nJobs = statuses[statusName];
                if (isNullOrUndefinedOrEmpty(nJobs)) {
                    timeseries[statusName].push(0);
                } else {
                    timeseries[statusName].push(nJobs);
                }
            })
        } else {
            statusNames.map(statusName => {
                timeseries[statusName].push(0);
            });
        }
        timeseries.date.push(`${month}/${year}`);
        start.setMonth(start.getMonth() + 1);
    }
    return timeseries;
}


const getPieChartData = (timeseries) => {
    console.log("timeseries statuses", timeseries);
    const pending = (timeseries[PENDING] || []).reduce((total, current) => {
        return total + current;
    }, 0)
    const enqueued = (timeseries[ENQUEUED] || []).reduce((total, current) => {
        return total + current;
    }, 0)
    const started = (timeseries[STARTED] || []).reduce((total, current) => {
        return total + current;
    }, 0)
    const complete = (timeseries[COMPLETE] || []).reduce((total, current) => {
        return total + current;
    }, 0)
    const errored = (timeseries[ERRORED] || []).reduce((total, current) => {
        return total + current;
    }, 0)

    const borderColor = ['rgb(0, 0, 0))',
        'rgb(245, 152, 66)',
        'rgb(245, 218, 66)',
        'rgb(72, 245, 66)',
        'rgb(255, 99, 132)',
    ]

    const backgroundColor = [
        'rgb(0, 0, 0, .5)',
        'rgb(245, 152, 66, .5)',
        'rgb(245, 218, 66, .5)',
        'rgb(0, 245, 0, .5)',
        'rgb(255, 0, 0, .5)',
    ];

    return {
        labels: ["Pending", "Enqueued", "Started", "Complete", "Errored"],
        datasets: [{
            data: [pending, enqueued, started, complete, errored],
            backgroundColor: backgroundColor,
            borderColor: borderColor,
        }],
    };
}


const getBarChartData = (timeseries) => {
    return {
        labels: timeseries.date,
        datasets: [
            {
                label: "Pending",
                data: (timeseries[PENDING] || []).map((nJobs, idx) => {
                    return { x: idx, y: nJobs }
                }),
                borderColor: 'rgb(0, 0, 0))',
                backgroundColor: 'rgb(0, 0, 0, .5)',
            },
            {
                label: "Enqueued",
                data: (timeseries[ENQUEUED] || []).map((nJobs, idx) => {
                    return { x: idx, y: nJobs }
                }),
                borderColor: 'rgb(245, 152, 66)',
                backgroundColor: 'rgb(245, 152, 66, .5)',
            },
            {
                label: "Started",
                data: (timeseries[STARTED] || []).map((nJobs, idx) => {
                    return { x: idx, y: nJobs }
                }),
                borderColor: 'rgb(245, 218, 66)',
                backgroundColor: 'rgb(245, 218, 66, .5)',
            },
            {
                label: "Complete",
                data: (timeseries[COMPLETE] || []).map((nJobs, idx) => {
                    return { x: idx, y: nJobs }
                }),
                borderColor: 'rgb(72, 245, 66)',
                backgroundColor: 'rgb(0, 245, 0, .5)',
            },
            {
                label: "Errored",
                data: (timeseries[ERRORED] || []).map((nJobs, idx) => {
                    return { x: idx, y: nJobs }
                }),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgb(255, 0, 0, .5)',
            },
        ],
    };

}

const BackfillStatus = ({ metrcAccountId, startDate, endDate }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const metrcBackfills = useSelector(state => state.integrationReducer.metrcBackfills);

    const [nRefreshes, setNRefreshes] = useState(0);
    const maxRefreshes = 20;
    const [sortOptions, setSortOptions] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        let refreshTimeout = setTimeout(() => {
            if (nRefreshes < maxRefreshes) {
                setNRefreshes(nRefreshes + 1);
            }
        }, 30000);
        setLoading(true);
        dispatch(fetchMetrcBackfills({
            metrc_account_id: metrcAccountId,
            start_date__gte: formatDate(startDate),
            start_date__lte: formatDate(endDate),
            limit: 2000
        }, abortController)).then((fetchedMetrcBackfills) => {
            dispatch(setMetrcBackfills(fetchedMetrcBackfills));
            setLoading(false);
        }).catch(err => {
            if (err !== ABORTED) {
                console.error(err);
            }
            setLoading(false);
        });
        return () => {
            abortController.abort()
            clearTimeout(refreshTimeout)
        }
    }, [metrcAccountId, startDate, endDate, nRefreshes]);

    if (loading) {
        return <Loading title={"Updating status..."} />
    }

    if (isNullOrUndefinedOrEmpty(metrcBackfills)) {
        return <p>You do not have any backfills in progress. Select a date range above and hit "START BACKFILLING".</p>
    }

    const timeseries = getTimeseries(binByStatusAndMonth(metrcBackfills, startDate, endDate), startDate, endDate);
    const totalTasks = metrcBackfills.length;
    const completeTasks = (metrcBackfills || []).reduce((nComplete, backfill) => {
        if (backfill.status === COMPLETE) {
            return nComplete + 1;
        }
        return nComplete;
    }, 0)
    console.log("complete", completeTasks, "total", totalTasks);
    const percentComplete = `${Math.round(completeTasks / totalTasks * 100)}%`;
    const barChartData = getBarChartData(timeseries);
    const pieChartData = getPieChartData(timeseries);
    const tableHeaders = [
        {
            Header: "Job ID",
            accessor: "id",
            id: "id"
        },
        {
            Header: "Status",
            accessor: "status",
            id: "status",
        },
        {
            Header: "Last Updated",
            accessor: "updated_at",
            id: "updated_at",
        },
        {
            Header: "Backfill Date",
            accessor: "start_date",
            id: "start_date",
        },
        {
            Header: "Package Status",
            accessor: "package_status",
            id: "package_status",
        },
    ];

    /*
            TODO: Implement a countdown timer. We need to decide what backfills to include in the countdown in calculating
            the start time for the backfill. If we update updated_at times for backfills when they're launched, we can
            send the latest backfill start time for the time window on the backfill objects. ALTERNATIVELY we could just implement a /metrc-backfills/status 
            endpoint to get the latest backfill status for a given metrc account and date range. This would be more efficient than fetching all backfills.

            <Grid item xs={12}>
                <Countdown start={???} complete={completeTasks} total={totalTasks} />
            </Grid>
    */

    return <Container className={"backfill-progress dashboard"}>
        <Grid container spacing={12} style={{ alignItems: "center" }}>
            <Grid item xs={6}>
                <Pie data={pieChartData} />
            </Grid>
            <Grid item xs={6}>
                <DashboardValue title={"for these dates"} content={percentComplete} units={"Complete"} />
            </Grid>
            <Grid item xs={12}>
                <Bar options={options} data={barChartData} />
            </Grid>
            <Grid item xs={12}>
                <p>The table below gives the status of each incomplete job dispatched to run your backfill. If you're seeing errors and want to file a bug report, please include at least one "errored" Job ID in your request.</p>
                <ReactTable
                    sorted={sortOptions}
                    onSortedChange={(newSorted, column, shiftKey) => {
                        setSortOptions(newSorted);
                    }}
                    showPaginationBottom={metrcBackfills && metrcBackfills.length > 50}
                    getProps={() => { return { id: "backfill-table" } }}
                    columns={tableHeaders}
                    sortOptions={sortOptions}
                    pageSize={50}
                    className="-striped -highlight mb-3"
                    data={metrcBackfills.filter(backfill => {
                        return backfill.status !== COMPLETE;
                    }).map(backfill => {
                        const lastUpdated = new Date(backfill.updated_at);
                        return {
                            id: backfill.id,
                            start_date: backfill.start_date,
                            status: statusLabels[backfill.status],
                            package_status: backfill.package_status,
                            updated_at: new Date(backfill.updated_at).toLocaleString()
                        }
                    })}
                />
            </Grid>
        </Grid>
    </Container>
}

export const Backfills = ({ metrcAccountId }) => {

    const dispatch = useDispatch();
    const [params, setParams] = useSearchParams()

    const [sendingBackfill, setSendingBackfill] = useState(false);

    const today = new Date();
    const lastYear = (new Date()).setMonth(today.getMonth() - 12);
    const defaultStart = params.get("startDate") ? dayjs(params.get("startDate")) : dayjs(lastYear);
    const defaultEnd = params.get("endDate") ? dayjs(params.get("endDate")) : dayjs(today);
    const [startDate, setStartDate] = useState(defaultStart);
    const [endDate, setEndDate] = useState(defaultEnd);

    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container className={"chart dashboard"}>
            <Container className={"chart-description"}>
                <Grid container spacing={12}>
                    <Grid item xs={12}>
                        <p>Backfills allow you to pull in historical data. When you change a token or add a new location you'll want to run a backfill from your start of business up until today. You can do that here.</p>
                        <p>There are a few caveats. Backfills are heavy work! You can only run a backfill for a given date once. If you need to re-backfill you have to wait until tomorrow. You can backfill once per day.</p>
                        <p>If you have problems please reach out via our <a href="https://canold.atlassian.net/servicedesk/customer/portal/3">support desk</a> by submitting a request for technical support. We'll get back to you asap.</p>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={12} className="backfill-date-range">
                            <Grid item xs={4}>
                                <DatePicker style={{ width: "100%" }} label="Start Date" value={dayjs(startDate)} onChange={(newDate) => {
                                    setStartDate(dayjs(newDate))
                                    params.set("startDate", formatDate(dayjs(newDate)));
                                    setParams(params);
                                }} />
                            </Grid>
                            <Grid item xs={4}>
                                <DatePicker label="End Date" value={dayjs(endDate)} onChange={(newDate) => {
                                    setEndDate(dayjs(newDate))
                                    params.set("endDate", formatDate(dayjs(newDate)));
                                    setParams(params);
                                }} />
                            </Grid>
                            <Grid item xs={3}>
                                <SubmitButton disabled={sendingBackfill} onSubmit={() => {
                                    setSendingBackfill(true);
                                    dispatch(kickoffBackfills({
                                        metrc_account_id: metrcAccountId,
                                        start_date: formatDate(startDate),
                                        end_date: formatDate(endDate),
                                        updated_at: new Date().toISOString(),
                                    })).then(kickedOffBackfills => {
                                        dispatch(setMetrcBackfills(kickedOffBackfills.results));
                                        setSendingBackfill(false);
                                    });
                                }}>Start Backfilling</SubmitButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {sendingBackfill ? <Loading title={"Please be patient, this can take a minute..."} />
                            : <BackfillStatus metrcAccountId={metrcAccountId} startDate={startDate} endDate={endDate} />}
                    </Grid>
                </Grid>
            </Container>
        </Container >
    </LocalizationProvider >
}
