import axios from "../axiosConfig.js";
import { isNullOrUndefinedOrEmpty, aborted, returnOrAbort } from "../utils.js";
import { ABORTED, ENQUEUE_SNACKBAR } from "./constants.js";

export const enqueueSnackbar = (notification) => {
    const key = notification.options && notification.options.key;
    return {
        type: ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random(),
        },
    };
};


export const handleErrorResponse = (err, abortController) => {
    return dispatch => {
        if (aborted(abortController)) {
            console.warn("Request aborted.");
            return Promise.reject(ABORTED);
        }

        if (err.response) {
            if (err.response.status === 401) {
                dispatch(enqueueSnackbar({
                    message: "You must be logged in to do that.",
                    options: {
                        variant: 'error',
                    },
                }));
            } else if (err.response.status === 403) {
                dispatch(enqueueSnackbar({
                    message: "You don't have privileges to do that.",
                    options: {
                        variant: 'error',
                    },
                }));
            }
            const data = err.response.data;
            if (data && data.non_field_errors) {
                return returnOrAbort(Promise.reject({ _message: data.non_field_errors.join(", ") }), Promise.reject(err), abortController);
            } else if (err.response.status === 500) {
                return returnOrAbort(Promise.reject({ _message: "An unexpected error occurred. Please try again later." }), Promise.reject(err), abortController);
            }

            if (aborted(abortController)) {
                console.warn("Request aborted. Returning null.");
                return null;
            }
            return returnOrAbort(Promise.reject(err.response), Promise.reject(err), abortController);
        }

        if (!aborted(abortController)) {
            dispatch(enqueueSnackbar({
                message: err.message,
                options: {
                    variant: 'error',
                },
            }));
        }

        return returnOrAbort(Promise.reject({ _message: err.request }), Promise.reject(err), abortController);
    }
};

export const destroyFromDal = (uri, primary_key, delete_message, abortController) => {
    return dispatch => {
        return axios.dal().delete(`${uri}${primary_key}/`, { signal: abortController ? abortController.signal : undefined }).then((response) => {
            dispatch(enqueueSnackbar({
                message: delete_message !== undefined ? delete_message : "Deleted.",
                options: {
                    variant: "success",
                },
            }));
            return response.data;
        }).catch((err) => {
            return dispatch(handleErrorResponse(err, abortController));
        });
    };
};

export const getFromDal = (uri, params, abortController) => {
    return dispatch => dispatch(get(uri, params, axios.dal(), abortController)).then((response) => {
        // Get list
        if (response.results) {
            return response.results;
        }
        // Get one
        return response;
    });
};

export const getFromApi = (uri, params, abortController) => {
    return dispatch => dispatch(get(uri, params, axios.api(), abortController)).then((response) => {
        // Get list
        if (response.results) {
            return response.results;
        }
        // Get one
        return response;
    });
};

export const get = (uri, params, using, abortController) => {
    if (abortController === undefined || abortController === null) {
        console.warn(`No abort controller passed for ${uri}`, abortController);
    }
    return dispatch => {
        return using.get(uri, { params: params, signal: abortController ? abortController.signal : undefined }).then((response) => {
            if (response === undefined) {
                return null;
            }
            return response.data;
        }).catch((err) => {
            return dispatch(handleErrorResponse(err, abortController));
        });
    }
};

export const post = ({ uri, payload, successMessage, using, abortController }) => {
    return dispatch => {
        return using.post(uri, JSON.stringify(payload), { signal: abortController ? abortController.signal : undefined }).then((response) => {
            if (response.status >= 300 || response.status < 200) {
                throw new Error("Uh oh! Looks like we're having a problem. We're actively working on it, please try again a bit later.")
            }
            let message = "Success!";
            if (isNullOrUndefinedOrEmpty(response.data) || isNullOrUndefinedOrEmpty(response.data.message)) {
                message = successMessage;
            } else {
                message = response.data.message || response.data.detail;
            }
            dispatch(enqueueSnackbar({
                message: message,
                options: {
                    variant: "success",
                },
            }));
            return response.data;
        }).catch((err) => {
            return dispatch(handleErrorResponse(err, abortController));
        });
    }
};

export const postToDal = (uri, payload, successMessage, abortController) => {
    return dispatch => dispatch(post({
        uri,
        payload,
        successMessage: successMessage,
        using: axios.dal(),
        abortController: abortController
    }));
};

export const postToApi = (uri, payload, successMessage, abortController) => {
    return dispatch => dispatch(post({
        uri,
        payload,
        using: axios.api(),
        successMessage: successMessage,
        abortController: abortController
    }));
};

export const patchToDal = (uri, partial, successMessage, abortController) => {
    return dispatch => {
        return axios.dal().patch(uri, partial, abortController).then((response) => {
            if (!isNullOrUndefinedOrEmpty(response.data) && !isNullOrUndefinedOrEmpty(response.data.message)) {
                dispatch(enqueueSnackbar({
                    message: response.data.message,
                    options: {
                        variant: "success",
                    },
                }));
            } else {
                dispatch(enqueueSnackbar({
                    message: successMessage || "Updated.",
                    options: {
                        variant: "success",
                    },
                }));
            }
            return response.data;
        }).catch((err) => {
            return dispatch(handleErrorResponse(err, abortController));
        });
    }
};

export const patchToApi = (uri, partial, successMessage, abortController) => {
    return dispatch => {
        return axios.api().patch(uri, partial, abortController).then((response) => {
            if (!isNullOrUndefinedOrEmpty(response.data) && !isNullOrUndefinedOrEmpty(response.data.message)) {
                dispatch(enqueueSnackbar({
                    message: response.data.message,
                    options: {
                        variant: "success",
                    },
                }));
            } else {
                dispatch(enqueueSnackbar({
                    message: successMessage || "Updated.",
                    options: {
                        variant: "success",
                    },
                }));
            }
            return response.data;
        }).catch((err) => {
            return dispatch(handleErrorResponse(err, abortController));
        });
    }
};

export const createUser = (user, abortController) => {
    user.email = user.username;
    return dispatch => {
        return dispatch(postToApi("/create-user/", user, "User created.", abortController));
    }
};

export const inviteUser = (params, abortController) => {
    return dispatch => {
        return dispatch(postToApi("/users/invite/", params, "User invited. Have them check their email and click the link to create a password.", abortController));
    }
}

export const createUserByEmail = (email, abortController) => {
    const params = { email: email, username: email, password: email }
    return dispatch => {
        return dispatch(postToApi("/create-user/", params, null, abortController));
    };
};


export const createDatabase = (database, abortController) => {
    return dispatch => dispatch(postToDal("/databases/", database, abortController));
};

export const deleteDatabase = (pk, abortController) => {
    return dispatch => dispatch(destroyFromDal("/databases/", pk, abortController));
};

export const fetchDatabases = (params, abortController) => {
    return dispatch => dispatch(getFromDal("/databases/", params, abortController));
};

export const fetchAddresses = (addressId = null, abortController) => {
    const params = {};
    if (addressId) {
        params['id'] = addressId;
    }

    return dispatch => dispatch(getFromDal("/addresses/", params, abortController)).then((response => {
        if (addressId) {
            return response[0];
        }
        return response;
    })).catch(err => {
        console.error(err);
        if (!aborted(abortController)) {
            dispatch(enqueueSnackbar({
                message: "An error happened while fetching addresses.",
                options: {
                    variant: 'error',
                },
            }));
        }
        return Promise.reject(err);
    });
}

export const updateAddress = (address, abortController) => {
    return dispatch => {
        return dispatch(patchToDal(`/addresses/${address.id}/`, address, 'Address updated.', abortController)).catch(err => {
            dispatch(enqueueSnackbar({
                message: "An error happened while updating the address.",
                options: {
                    variant: 'error',
                },
            }));
            return Promise.reject(err);
        });
    }
};

export const createAddress = (address, abortController) => {
    const addressRequest = {
        ...address,
        id: undefined,
    };
    return dispatch => {
        return dispatch(postToDal(`/addresses/`, addressRequest, 'Address created.', abortController)).then((response) => {
            return response
        }).catch(err => {
            dispatch(enqueueSnackbar({
                message: "An error happened while creating the address. Try again soon, please.",
                options: {
                    variant: 'error',
                },
            }));
            return Promise.reject(err);
        });
    }
};

export const generatePasswordToken = (email, abortController) => {
    return dispatch => dispatch(postToApi('/accounts/forgot-password/', {
        email: email
    }, "Success. Check your email.", abortController)).then((response) => {
        return response.data;
    });
};

export const resetPassword = (password, abortController) => {
    return dispatch => dispatch(postToApi('/accounts/forgot-password/confirm/', {
        password: password,
    }, "Password changed"), abortController).then((response) => {
        return response.data;
    });
};

export const voteForIntegration = (vote, abortController) => {
    return dispatch => dispatch(
        postToDal("/integration-votes/", vote, "Thanks for your vote! If this integration is popular we'll prioritize it.", abortController)
    ).then((response) => {
        return response.data;
    });
}


export const getIntegrationVotes = (abortController) => {
    return dispatch => dispatch(
        getFromDal("/integration-votes/", {}, abortController)
    ).then((response) => {
        return response.data;
    });
}


export const fetchSalesReport = (params, abortController) => {
    return dispatch => dispatch(
        getFromApi("/sales-reporting/", params, abortController)
    ).then((response) => {
        return response;
    });
}

export const fetchLatestPurchasesReport = (abortController) => {
    return dispatch => dispatch(
        getFromApi("/sales-reporting/customers/last-purchase/", abortController)
    ).then((response) => {
        return response;
    });

}
