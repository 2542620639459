import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TableHead, TableCell, TableRow, Table, TableBody, Container, Grid } from "@mui/material";
import Loading from '../../components/elements/Loading.js';
import CreateNewOrganization from '../../components/forms/CreateNewOrganization.js';
import OrganizationButtons from '../../components/forms/OrganizationButtons.js';
import {ORGANIZATION_COOKIE} from "../../redux/constants.js";
import {isNullOrUndefinedOrEmpty, userIsNotLoggedIn, setCookie} from "../../utils.js";
import "../styles/MainPage.css";


const List = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isLoading = useSelector(state => state.userReducer.loading);
    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const roleByOrganizationId = organizationUsers.reduce((accumulator, currentValue) => {
        accumulator[currentValue.organization_id] = currentValue.role
        return accumulator;
    }, {});

    if (isLoading) {
        return <Loading />
    }

    if (userIsNotLoggedIn(loggedInUser)) {
        navigate("/login");
        return null;
    }

    if (organizations.length === 0) {
        return <Container className={"workspace"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h1>Manage Organizations</h1>
                    <p>Organizations help you organize your integrations. Most of the time you'll want to have just one organization for all your facilities. This page is to manage your organizations.</p>
                    <h3>You do not belong to, nor have you been invited to any organizations.</h3>
                    <CreateNewOrganization alreadyOpen={true}/>
                </Grid>
            </Grid>
        </Container>
    }

    return (
        <Container className={"workspace"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h1>Manage Organizations</h1>
                    <p>Organizations help you organize your integrations. Most of the time you'll want to have just one organization for all your facilities. This page is to manage your organizations.</p>
                    {isLoading ? <Loading /> :
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="col-3">
                                    <Grid container spacing={12}>
                                        <Grid item xs={6}>
                                            Organization Name
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CreateNewOrganization displayText={"Add"} />
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell className="col-3">
                                    Your Role
                                </TableCell>
                                <TableCell className="col-3"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {organizations.sort((a, b) => {
                                return a.name.localeCompare(b.name);
                            }).map((organization, idx) => (
                                <TableRow key={idx} id={organization.name}>
                                    <TableCell id={`organization-name-${organization.id}`} className="col-3">
                                        <Link onClick={(event) => {
                                            event.preventDefault();
                                            setCookie(ORGANIZATION_COOKIE, organization.id);
                                            navigate(`/organizations/${organization.id}/detail`);
                                        }}>
                                            {organization.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell id={`user-role-${idx}`} className="col-3">
                                        <Link onClick={(event) => {
                                            event.preventDefault();
                                            setCookie(ORGANIZATION_COOKIE, organization.id);
                                            navigate(`/organizations/${organization.id}/detail`);
                                        }}>
                                            {isNullOrUndefinedOrEmpty(roleByOrganizationId[organization.id]) ? "superuser" : roleByOrganizationId[organization.id]}
                                        </Link>
                                    </TableCell>
                                    <TableCell id={`organization-buttons-${idx}`} className="col-3 invitation-buttons">
                                        <OrganizationButtons
                                            organization={organization}
                                            roleByOrganizationId={roleByOrganizationId}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>}
                </Grid>
            </Grid>
        </Container>
    );
};

export default List;
