import React from 'react';
import {
    Chart as ChartJS,
    registerables
} from 'chart.js';
import {Bubble} from 'react-chartjs-2';


ChartJS.register(...registerables);

export const options = {
        plugins: {
            title: {
                text: "Product Velocity",
                display: true
            },
            legend: {
                position: "left"
            }
        },
        scales: {
            y: {
                title: {
                    text: "Units Sold",
                    display: true
                },
                beginAtZero: true,
            },
            x: {
                title: {
                    text: "Days Available",
                    display: true
                },
            }
        }
    };


export const data = {
    datasets: [
        {
            label: 'Bulk Flower',
            data: Array.from({length: 10}, () => ({
                x: Math.random() * 100,
                y: Math.random() * 75 + 25,
                r: parseInt(Math.random() * 25 + 5),
            })),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Cartridges',
            data: Array.from({length: 10}, () => ({
                x: Math.random() * 75 + 25,
                y: Math.random() * 100,
                r: parseInt(Math.random() * 25 + 5),
            })),
            backgroundColor: 'rgba(99, 255, 132, 0.5)',
        },
        {
            label: 'Pre-rolls',
            data: Array.from({length: 10}, () => ({
                x: Math.random() * 50,
                y: Math.random() * 50,
                r: parseInt(Math.random() * 25 + 5),
            })),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};

export const Velocity = () => {
    return <div className={"chart"}>
        <div className={"chart-description"}>
            <h1>Product Trajectory</h1>
            <p>Knowing at what rate products are flying off the shelves helps you plan you inventory ahead.</p>
            <p>Knowing if that trend will continue allows you to plan your inventory <i>well</i>.</p>
        </div>
        <div className={"velocity"}>
            <Bubble options={options} data={data}/>
        </div>
    </div>
}

export default Velocity;
