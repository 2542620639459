import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isNullOrUndefinedOrEmpty, userIsNotLoggedIn } from "../../../utils.js";
import { TableHead, TableCell, TableRow, TableContainer, Table, TableBody, Container } from "@mui/material";
import { fetchMetrcAccounts } from "../../../redux/models/integrations/Action.js";


const List = () => {
    const abortController = new AbortController();

    const [metrcAccounts, setMetrcAccounts] = useState([]);

    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isNullOrUndefinedOrEmpty(organizations)) {
            dispatch(fetchMetrcAccounts(abortController)).then((fetchedMetrcAccounts) => {
                setMetrcAccounts(fetchedMetrcAccounts);
            });
            return () => {
                abortController.abort();
            }
        }
    }, [loggedInUser.id, organizations]);

    if (userIsNotLoggedIn(loggedInUser)) {
        navigate("/login");
        return "User is not logged in.";
    }

    const noIntegrations = <Link to="/integrations/metrc/setup">
        You have no metrc integrations. Go to Integrations, then Setup to add some or click here.
    </Link>;

    return <Container className={"workspace"}>
        <h1>Manage Metrc Integrations</h1>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Organization Name</TableCell>
                        <TableCell>Metrc Account Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        isNullOrUndefinedOrEmpty(metrcAccounts) ? noIntegrations : metrcAccounts.sort((a, b) => {
                            return a.display_name.localeCompare(b.display_name);
                        }).map((metrcAccount) => {
                            const organization = organizations.find(o => metrcAccount.organization_id === o.id)
                            if (isNullOrUndefinedOrEmpty(organization)) {
                                return ""
                            }
                            return <TableRow key={metrcAccount.id}>
                                <TableCell>
                                    <Link to={`/integrations/metrc/${metrcAccount.id}/detail`}>
                                        {organization.name}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Link to={`/integrations/metrc/${metrcAccount.id}/detail`}>
                                        {metrcAccount.display_name}
                                    </Link>
                                </TableCell>
                            </TableRow>;
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </Container>
};

export default List;