import {
    SELECT_ORGANIZATION
} from "../../constants.js";
import { getCookie, setCookie } from "../../../utils.js";

const defaultState = {
    selectedOrganization: null
};

const OrganizationReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SELECT_ORGANIZATION:
            console.log("Selecting organization", action.organization.id);
            setCookie("organizationId", action.organization.id);
            console.log(`Cookie is now ${getCookie('organizationId')}`)
            return {
                ...state,
                selectedOrganization: action.organization
            };
        default:
            return state;
    }
};


export default OrganizationReducer;
