import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { isNullOrUndefinedOrEmpty } from "../utils.js";
import { IconButton } from "@mui/material";
import Loading from "./elements/Loading.js";
import {
    Done as DoneIcon,
    Info as NotDoneIcon

} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
    ADMIN_ROLE,
    ROUTES_INTEGRATIONS_METRC_SETUP,
    ROUTES_ORGANIZATIONS_LIST,
    SUPERADMIN_ROLE,
} from '../utils/Constants.js';
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import {
    setUserProfile,
    updateUserProfile,
} from "../redux/user/Action.js";
import Tooltip from "./Tooltip.js";
import { selectOrganization } from "../redux/models/organizations/Action.js";
import SubmitButton from "./elements/SubmitButton.js";


const done = () => {
    return <span>
        <DoneIcon className={"good"} /> &nbsp;
    </span>
}

const undone = (tooltip) => {
    return <Tooltip title={tooltip}>
        <IconButton>
            <NotDoneIcon />
        </IconButton>
    </Tooltip>
}

export const SetupDialog = ({ open, pendingRequests, toggle, loggedInUserProfile, organization, children }) => {
    const dispatch = useDispatch();

    const maybeHidden = isNullOrUndefinedOrEmpty(loggedInUserProfile) || loggedInUserProfile.hide_wizard ? "hidden" : "";
    const maybeClosed = open ? "" : "hidden";

    return <div className={`setup-wizard-dialog ${maybeHidden}`}>
        <DialogTitle className="setup-wizard-header" onClick={toggle}>Let's finish getting you set up!</DialogTitle>
        <DialogContent className={maybeClosed}>
            {isNullOrUndefinedOrEmpty(organization) ? "" : <p>You're the admin for {organization.name}.</p>}
            {pendingRequests > 0 ? <Loading /> : children}
        </DialogContent>
        <DialogActions className={maybeClosed}>
            <span id="skip-setup-wizard" className={`clickable ${maybeClosed}`} style={{ float: "left", display: "block" }} onClick={toggle}>Hide</span> &nbsp;&nbsp;
            <SubmitButton className={maybeClosed} onClick={() => {
                dispatch(updateUserProfile({ id: loggedInUserProfile.id, hide_wizard: true })).then(updatedUserProfile => {
                    dispatch(setUserProfile({ ...updatedUserProfile, hide_wizard: true }));
                })
            }}>Don't show me this again</SubmitButton>
        </DialogActions>
    </div>
}

export const Bullet = ({ name, isDone, text, tooltip, route, isExternal, isDisabled, setOpen, navigate, onClick }) => {
    if (isExternal) {
        return <div key={"setup-wizard-bullet" + name}>
            {isDone ? done() : undone(tooltip)}
            <span>
                {isDone || isDisabled ? <span className={isDone || isDisabled ? "disabled" : ""}>{text}</span> : <a className="clickable" href={route}>{text}</a>}
            </span>
        </div>
    }
    return <div key={"setup-wizard-bullet" + name}>
        {isDone ? done() : undone(tooltip)}
        <span>
            {isDone || isDisabled ? <span className={isDone || isDisabled ? "disabled" : ""}>{text}</span> :
                <span className="clickable" onClick={
                    (event) => {
                        setOpen(false)
                        navigate(route);
                        if (onClick) {
                            onClick(event);
                        }
                    }
                }>{text}</span>}
        </span>
    </div>
}


const SetupWizard = ({ loggedInUser, loggedInUserProfile, overrideOpen = false }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const targetOrganization = useSelector(state => state.organizationReducer.selectedOrganization) || {};
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const organizationUsers = useSelector(state => state.userReducer.loggedInUser.organizationUsers);
    const accountStatuses = useSelector(state => state.userReducer.loggedInUser.accountStatuses);
    const accountStatus = accountStatuses.find(accountStatus => {
        if (isNullOrUndefinedOrEmpty(targetOrganization)) {
            return !isNullOrUndefinedOrEmpty(organizationUsers.find(ou => ou.organization_id === accountStatus.organization_id && (ou.role === ADMIN_ROLE || ou.role === SUPERADMIN_ROLE)));
        }
        return accountStatus.organization_id === targetOrganization.id
    });

    const setupIsIncomplete = () => {
        if (isNullOrUndefinedOrEmpty(loggedInUser)) {
            return false;
        }
        if (isNullOrUndefinedOrEmpty(accountStatus)) {
            return true;
        }
        if (organizations.length === 0) {
            return true;
        }
        return (
            !accountStatus.has_locations ||
            !accountStatus.has_integration
        )
    };

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (setupIsIncomplete()) {
            setOpen(true);
        }
    }, [
        loggedInUser && loggedInUser.id,
        organizations && organizations.length,
        accountStatuses && accountStatuses.length,
        targetOrganization && targetOrganization.id
    ]);

    if (!isNullOrUndefinedOrEmpty(loggedInUserProfile) && loggedInUserProfile.hide_wizard) {
        return "";
    }

    const currentUsersRole = (organizationUsers.find(ou => ou.organization_id === targetOrganization.id) || {}).role
    if (!isNullOrUndefinedOrEmpty(targetOrganization) && currentUsersRole !== ADMIN_ROLE && currentUsersRole !== SUPERADMIN_ROLE) {
        return "";
    }
            
    if (!setupIsIncomplete()) {
        return "";
    }

    return <SetupDialog
        open={open || overrideOpen}
        pendingRequests={0}
        toggle={() => {
            setOpen(!open)
        }}
        loggedInUserProfile={loggedInUserProfile}
        organization={targetOrganization}
    >
        <Bullet
            name={"organization"}
            isDone={organizations.length > 0}
            text={"Create or join an organization."}
            tooltip={"You have to associate your integrator accounts with an organization. This allows us to share access to your account with other people in your organization you would like to share with. Your organization represents your whole business, even if you have multiple metrc accounts."}
            route={ROUTES_ORGANIZATIONS_LIST + "/?open=1"}
            isExternal={false}
            isDisabled={false}
            setOpen={setOpen}
            navigate={navigate}
        />
        <Bullet
            name={"integration"}
            isDone={accountStatus && accountStatus.has_integration}
            text={"Add an integration."}
            tooltip={"Once you've added an organization, the next step is to add an integration so we can start pulling data into your account. This gives us permission to access your API data according to our terms of service."}
            route={ROUTES_INTEGRATIONS_METRC_SETUP}
            isExternal={false}
            isDisabled={organizations.length === 0}
            setOpen={setOpen}
            navigate={navigate}
            onClick={() => { dispatch(selectOrganization(targetOrganization)) }}
        />
        <Bullet
            name={"locations"}
            isDone={accountStatus && accountStatus.has_locations}
            text={"Configure wholesale inventory locations. "}
            tooltip={"Once we've got everything else all set up we need to know from which location(s) to sell your inventory. This determines which rooms store available inventory."}
            route={`${process.env.REACT_APP_WHOLESALE_URL}/locations/list/`}
            isExternal={true}
            isDisabled={!(accountStatus && accountStatus.has_integration)}
            setOpen={setOpen}
            navigate={navigate}
        />
    </SetupDialog>
};

export default SetupWizard;
