import { POPUP_TYPE_CONFIRM, POPUP_TYPE_INFORMATIONAL, POPUP_TYPE_RESET_PASSWORD } from "../../redux/constants.js";
import InformationalPopup from "./InformationalPopup.js";
import ResetPassword from "../forms/ResetPassword.js";
import { useSelector } from "react-redux";
import ConfirmationPopup from "./ConfirmationPopup.js";

export const Popup = () => {

    const popup = useSelector(state => state.notificationsReducer.popup);

    if (popup.type === POPUP_TYPE_INFORMATIONAL) {
        return <InformationalPopup />
    } else if (popup.type === POPUP_TYPE_CONFIRM) {
        return <ConfirmationPopup />
    } else if (popup.type === POPUP_TYPE_RESET_PASSWORD) {
        return <ResetPassword />
    }
    return ""
}

export default Popup;