import {useRef, useEffect, useState} from "react";
import {useSelector} from "react-redux";


export const ScrollEventWrapper = ({
    name="",
                                       addOnTrigger = [],
                                       addOffTrigger = [],
                                       exitDelay = 0,
                                       className = "",
                                       children
                                   }) => {
    /*
    @param addOnTrigger: is a set of class names to add when the element comes into view.
    @param addOffTrigger: is a set of class names to add when the element goes out of view.
     */
    const wrapper = useRef(null);

    const [classNames, setClassNames] = useState(new Set());
    const [triggered, setTriggered] = useState(false);

    const upperFold = useSelector(state => state.animationReducer.window.upperFold);
    const lowerFold = useSelector(state => state.animationReducer.window.lowerFold);

    const trigger = () => {
        if (triggered) {
            return;
        }

        setTriggered(true);

        if (addOnTrigger) {
            addOnTrigger.map(className => {
                classNames.add(className);
            })
        }
        if (addOffTrigger) {
            addOffTrigger.map(className => {
                classNames.delete(className);
            })
        }
        classNames.delete("invisible");
        setClassNames(classNames);
    }

    const untrigger = () => {
        if (!triggered) {
            return;
        }

        setTriggered(false);

        if (addOnTrigger) {
            addOnTrigger.map(className => {
                classNames.delete(className);
            })
        }
        if (addOffTrigger) {
            addOffTrigger.map(className => {
                classNames.add(className);
            })
        }
        classNames.add("invisible");
        setClassNames(classNames);
    }

    useEffect(() => {
        if (wrapper.current !== null && parseInt(upperFold) % 5 === 0) {
            const rect = wrapper.current.getBoundingClientRect();

            if (upperFold === null || lowerFold === null) {
                return;
            }

            const elementTop = -rect.top + upperFold;
            const elementBottom = -rect.bottom + upperFold;

            if (elementTop === null || elementBottom === null) {
                return;
            }

            // Element Top is below the lower fold: Hidden
            // Element Bottom is above the upper fold: Hidden
            if (elementTop < lowerFold || elementBottom > upperFold) {
                if (triggered) untrigger();
            }

            // Element Top is above the lower fold: Displayed
            // Element Bottom is below the upper fold: Displayed
            if (elementTop > lowerFold || elementBottom < lowerFold) {
                if (!triggered) trigger();
            }
        }
    }, [upperFold, lowerFold]);

    return <div
        ref={wrapper}
        className={`${className} scroll-event-wrapper ${Array.from(classNames).join(" ")} ${triggered ? "" : "invisible"}`}
    >
        {children}
    </div>

}

export default ScrollEventWrapper;