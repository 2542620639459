import React from "react";

import { Tooltip as MuiToolTip } from "@mui/material";

const Tooltip = ({ children, title, ...props }) => {
    return <MuiToolTip title={title} {...props}>
        {children}
    </MuiToolTip>
}

export default Tooltip;
