import { LinearProgress } from "@mui/material";

const Loading = ({ title }) => {
    return <div><br />
        <p>{title || "Loading..."}</p>
        <LinearProgress />
    </div>

}

export default Loading;
