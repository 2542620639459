// @ts-check
import axios from "../../axiosConfig.js";
import { isNullOrUndefinedOrEmpty, aborted, setCookie } from "../../utils.js";
import { destroyFromDal, enqueueSnackbar, getFromApi, getFromDal, patchToDal, postToApi, postToDal, patchToApi } from "../Actions.js";
import { LOGGED_IN_USER_IS_LOADING, ADD_ACCOUNT_STATUS, SET_ARRIVED_VIA_INVITATION, SET_BANK_ACCOUNTS, SET_BANK_ACCOUNT_ADDRESS, SET_LOGO, SET_USER_PROFILE, USER_LOGGED_IN, SET_ORGANIZATION_USERS } from '../constants.js';
import { SET_USER } from "../constants.js";
import { NO_LOGO } from "../../utils/Constants.js";
import { JWT_COOKIE, ORGANIZATION_COOKIE, UPDATE_ACCOUNT_STATUS } from "../constants.js";


export const userLoggedIn = (userData) => {
    console.log(`user logged in ${userData}`);
    return dispatch => {
        return dispatch({ type: USER_LOGGED_IN, ...userData });
    };
};

// Logos
export const setLogo = (logo, organizationId) => {
    return dispatch => {
        return dispatch({
            type: SET_LOGO,
            logo: logo,
            organizationId: organizationId
        });
    }
}
export const fetchLogo = (organizationId, abortController) => {
    const params = { organizations__id: organizationId };
    return dispatch => dispatch(getFromDal("/logos/", params, abortController)).then((response => {
        if (isNullOrUndefinedOrEmpty(response)) {
            return Promise.reject(NO_LOGO);
        } else {
            return response[0];
        }
    })).catch(err => {
        if (!aborted(abortController) && err !== NO_LOGO) {
            dispatch(enqueueSnackbar({
                message: "An error happened while retrieving the organization logo.",
                options: {
                    variant: 'error',
                },
            }));
        }
        return Promise.reject(err);
    });
}
export const uploadFiles = (logo, organizationId, abortController) => {
    const formData = new FormData();
    formData.append('logo', logo);
    formData.append('organization_id', organizationId);
    return dispatch => {
        return axios.api().post("/logos/upload/", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            signal: abortController ? abortController.signal : undefined
        }).then((response) => {
            dispatch(enqueueSnackbar({
                message: "Successfully uploaded.",
                options: {
                    variant: 'success',
                },
            }));
            return response;
        }).catch((err) => {
            let message = "Failed to upload files.";
            if (!aborted(abortController)) {
                dispatch(enqueueSnackbar({
                    message: message,
                    options: {
                        variant: 'error',
                    },
                }));
                if (err && err.response) {
                    return err.response.data;
                }
            }
        });
    }
}

export const setBankAccounts = (organization, bankAccounts) => {
    return dispatch => {
        return dispatch({
            type: SET_BANK_ACCOUNTS,
            organization: organization,
            bankAccounts: bankAccounts
        });
    }
}
export const fetchBankAccounts = (organizationId, abortController) => {
    const params = isNullOrUndefinedOrEmpty(organizationId) ? {} : { organization_id: organizationId };
    return dispatch => dispatch(getFromDal("/bank-accounts/", params, abortController)).then((response => {
        return response;
    })).catch(err => {
        if (!aborted(abortController))
            dispatch(enqueueSnackbar({
                message: "An error happened while fetching bank accounts.",
                options: {
                    variant: 'error',
                },
            }));
        return Promise.reject(err);
    });
}
export const createBankAccount = (bankAccount, abortController) => {
    const bankAccountRequest = {
        ...bankAccount,
        id: undefined,
    };
    return dispatch => {
        return dispatch(postToDal(`/bank-accounts/`, bankAccountRequest, 'Bank Account created.', abortController)).catch(err => {
            if (!aborted(abortController)) {
                dispatch(enqueueSnackbar({
                    message: "An error happened while creating the bank account.",
                    options: {
                        variant: 'error',
                    },
                }));
            }
            return Promise.reject(err);
        });
    }
};
export const updateBankAccount = (bankAccount, abortController) => {
    return dispatch => {
        return dispatch(patchToDal(`/bank-accounts/${bankAccount.id}/`, bankAccount, 'Bank Account updated.', abortController)).catch(err => {
            if (!aborted(abortController)) {
                dispatch(enqueueSnackbar({
                    message: "An error happened while updating the bank account.",
                    options: {
                        variant: 'error',
                    },
                }));
            }
            return Promise.reject(err);
        });
    }
};
export const deleteBankAccount = (bankAccountId, abortController) => {
    return dispatch => dispatch(destroyFromDal(`/bank-accounts/`, bankAccountId, 'Bank Account deleted.', abortController));
};


export const setBankAccountAddress = (organization, bankAccount, address) => {
    return dispatch => {
        return dispatch({
            type: SET_BANK_ACCOUNT_ADDRESS,
            organization: organization,
            bankAccount: bankAccount,
            address: address
        });
    };
};
export const acceptTermsOfService = (loggedInUser, abortController) => {
    const params = { has_accepted_tos: true };
    return dispatch => dispatch(
        patchToDal(`/user-profiles/${loggedInUser.id}/`, params, "Terms of Service accepted.", abortController)
    ).then((response) => {
        return response.data;
    });
};
export const setUserProfile = (userProfile) => {
    return dispatch => {
        return dispatch({
            type: SET_USER_PROFILE,
            profile: userProfile
        });
    }
}

export const setUser = (user) => {
    return dispatch => {
        return dispatch({
            type: SET_USER,
            user: user
        })
    }
}

export const fetchUserProfile = (user, abortController) => {
    return dispatch => {
        return dispatch(getFromDal(`/user-profiles/?user_id=${user.id}`, {}, abortController)).then(userProfiles => {
            if (userProfiles.length > 0) {
                return userProfiles[0];
            }
            return null
        });
    }
}

export const fetchCurrentUser = (abortController) => {
    return dispatch => dispatch(getFromApi("/users/me/", {}, abortController));
};
export const fetchUser = (userId, abortController) => {
    return dispatch => dispatch(getFromDal(`/users/${userId}/`, {}, abortController));
};
export const fetchUsers = (params, abortController) => {
    return dispatch => dispatch(getFromDal("/users/", params, abortController)).then(maybeFetchedUsers => {
        if (maybeFetchedUsers.length === 0) {
            return Promise.reject({ detail: 'no user found.' });
        }
        return maybeFetchedUsers;
    }).catch(err => {
        return Promise.reject(err);
    });
};
export const updateUserRole = (throughId, newRole, successMessage, abortController) => {
    const params = { role: newRole };
    return dispatch => dispatch(patchToDal(`/organization-users/${throughId}/`, params, successMessage || 'Invitation accepted.', abortController));
};
export const logout = (abortController) => {
    return dispatch => dispatch(getFromApi('/accounts/logout/', {}, abortController)).then((response) => {
        setCookie(JWT_COOKIE, "");
        setCookie(ORGANIZATION_COOKIE, "");
        return response.data;
    });
};
export const login = (email, password, abortController) => {
    return dispatch => dispatch(postToApi('/accounts/login/', {
        username: email,
        email: email,
        password: password
    }, "Logged in successfully", abortController)).then((response) => {
        return response;
    });
};
export const updateUser = (partial, abortController) => {
    if (partial.email) {
        partial.username = partial.email;
        delete partial.email;
    }
    return dispatch => {
        const userId = partial.id;
        const params = { ...partial };
        delete params.id;
        return dispatch(patchToApi(`/users/${userId}/`, params, abortController));
    }
};


export const updateUserProfile = (partial) => {
    return dispatch => {
        const userId = partial.id;
        delete partial.id;
        return dispatch(patchToDal(`/user-profiles/${userId}/`, partial))
    }
}


export const fetchOrganization = (organizationId, abortController) => {
    return dispatch => dispatch(getFromDal(`/organizations/${organizationId}/`, {}, abortController));
};

export const fetchGroups = (abortController) => {
    return dispatch => dispatch(getFromDal(`/groups/`, {}, abortController));
};

export const fetchOrganizations = (organizationIds, abortController) => {
    var params = {}
    if (!isNullOrUndefinedOrEmpty(organizationIds)) {
        params["id__in"] = organizationIds.join(',')
    }
    return dispatch => dispatch(getFromDal("/organizations/", params, abortController));
};

export const fetchOrganizationUsers = (organizationId, userId, abortController) => {
    const params = {}
    if (!isNullOrUndefinedOrEmpty(organizationId)) {
        params["organization_id"] = organizationId;
    }
    if (!isNullOrUndefinedOrEmpty(userId)) {
        params["user_id"] = userId;
    }
    return dispatch => dispatch(getFromDal(`/organization-users/`, params, abortController));
};


export const setOrganizationUsers = (organizationUsers) => {
    return dispatch => {
        return dispatch({
            type: SET_ORGANIZATION_USERS,
            organizationUsers: organizationUsers
        })
    }
};

export const fetchOrganizationUserGroups = (organizationId, abortController) => {
    const params = {
        organization_user__organization_id: organizationId,
    };
    return dispatch => dispatch(getFromDal(`/organization-user-groups/`, params, abortController));
};

export const fetchOrganizationUserGroupsForUser = (user, abortController) => {
    const params = { organization_user__user_id: user.id };
    return dispatch => dispatch(getFromDal(`/organization-user-groups/`, params, abortController));
};

export const createOrganization = (name, createdBy, abortController) => {
    const params = {
        name: name,
        created_by_id: createdBy.id
    };
    return dispatch => {
        return dispatch(postToDal("/organizations/", params, 'Organization created.', abortController))
    };
};

export const createOrganizationUser = (organizationId, userId, role, abortController) => {
    const params = {
        organization_id: organizationId,
        user_id: userId,
        role: role
    };
    return dispatch => {
        return dispatch(postToDal("/organization-users/", params, 'User added.', abortController)).catch(() => {
            if (!aborted(abortController)) {
                dispatch(enqueueSnackbar({
                    message: 'This user already belongs to this organization or you don\'t have permission to add them.',
                    options: {
                        variant: 'error',
                    },
                }));
            }
        });
    };
};

export const updateOrganization = (partial, abortController) => {
    return dispatch => {
        const organizationId = partial.id;
        return dispatch(patchToDal(`/organizations/${organizationId}/`, partial, 'Organization updated.', abortController)).catch(err => {
            if (!aborted(abortController)) {
                dispatch(enqueueSnackbar({
                    message: 'Cannot rename organization to requested name.',
                    options: {
                        variant: 'error',
                    },
                }));
            }
            return Promise.reject(err);
        });
    };
};

export const deactivateOrganization = (organizationId, abortController) => {
    const params = { is_active: false };
    return dispatch => dispatch(patchToDal(`/organizations/${organizationId}/`, params, 'Organization deleted.', abortController));
};

export const deleteOrganizationUser = (id, abortController) => {
    return dispatch => dispatch(destroyFromDal(`/organization-users/`, id, 'Removed from organization.', abortController));
};

export const updateAccountStatus = (accountStatus) => {
    return dispatch => {
        return dispatch({
            type: UPDATE_ACCOUNT_STATUS,
            accountStatus: accountStatus,
        })
    }
};

export const addAccountStatus = (accountStatus) => {
    return dispatch => {
        return dispatch({
            type: ADD_ACCOUNT_STATUS,
            accountStatus: accountStatus,
        })
    }
};

export const setArrivedViaInvitation = () => {
    return dispatch => dispatch({ type: SET_ARRIVED_VIA_INVITATION });
};

export const loggedInUserIsLoading = (isLoading) => {
    return dispatch => dispatch({ type: LOGGED_IN_USER_IS_LOADING, isLoading: isLoading });
};

export const createOrgUserGroups = (groupId, organizationUserId, abortController) => {
    const params = {
        group_id: groupId,
        organization_user_id: organizationUserId
    };
    return dispatch => dispatch(
        postToDal(
            "/organization-user-groups/", params, "Group added", abortController
        )
    ).then((response) => {
        return response;
    });
};

export const destroyOrgUserGroups = (organizationUserGroupId, abortController) => {
    return dispatch => dispatch(
        destroyFromDal(
            "/organization-user-groups/", organizationUserGroupId, "Group removed", abortController
        )
    ).then((response) => {
        return response;
    });
};
