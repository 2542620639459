import { React } from "react";
import { useEffect } from "react";

import { registerScrollEvent, onScroll } from "../../redux/animations/Action.js";
import { useDispatch, useSelector } from "react-redux";
import { userIsLoggedIn } from "../../utils.js";
import { Advertiser } from "./Advertiser.js"
import { Dashboard } from "./Dashboard.js"


const youreOnTheWaitlist = () => {
    return (<>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card card-body">
                        <h1 className="text-center">You're on the waitlist!</h1>
                        <p className="text-center">We'll let you know when you can start using the platform.</p>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

const Waitlist = () => {
    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);

    userIsLoggedIn(loggedInUser);

    const dispatch = useDispatch();

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;

        document.body.appendChild(script);
        dispatch(registerScrollEvent());

        window.addEventListener("scroll", onScroll);

        return () => {
            document.body.removeChild(script);
            window.removeEventListener("scroll", onScroll);
        }
    }, [true]);

    return (<>
        {userIsLoggedIn(loggedInUser)
            ? youreOnTheWaitlist()
            : <Advertiser/>
        }
    </>);
}

export default Waitlist;
