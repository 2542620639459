import Button from "@mui/material/Button/Button.js";
import React from "react";
import { isNullOrUndefinedOrEmpty } from "../../utils.js";

const SubmitButton = (props) => {
    return <Button
        className={`submit ${props.className}`}
        disabled={props.disabled}
        id={isNullOrUndefinedOrEmpty(props.id) ? "submit" : props.id}
        variant="contained"
        type="submit"
        color="primary"
        onClick={props.onSubmit || props.onClick}
        >
        {props.children}
    </Button>
}

export default SubmitButton;