import {ScrollEventWrapper} from "../../components/elements/ScrollEventWrapper.js";


export const Wholesale = () => {
    return <div className={"wholesale-features"}>
        <div className={"row"}>
            <ScrollEventWrapper className="wholesale-feature left" name="wholesale-feature"
                                addOnTrigger={["swoop-left"]}>
                <h2>Customer/Contact Management</h2>
                <p>Manage customers and contacts. Track them, and assign them to sales representatives.</p>
                <p>When reps build a quote, those customers are guaranteed the inventory will be available until the quote is sold.</p>
                <img src={"/wholesale/customers-and-contacts.png"}/>
            </ScrollEventWrapper>
            <ScrollEventWrapper className="wholesale-feature middle" name="wholesale-feature"
                                addOnTrigger={["swoop-right"]}>
                <h2>Build, export, and download sales quotes.</h2>
                <p>It's a breeze. Automatically pull in your metrc packages with 60s resolution. Populate only packages allocated to the logged in user to prevent double-sales.</p>
                <img src={"/wholesale/sales-quotes.png"}/>
            </ScrollEventWrapper>
            <ScrollEventWrapper className="wholesale-feature right" addOnTrigger={["swoop-left"]}>
                <h2>Add orders to the fulfillment queue.</h2>
                <p>When a quote is ready you just push "Ready to Fulfill" and the fulfullment team does the rest.</p>
                <p>All the line items on the quote goes to the fulfillment queue where the fulfillment team and managers can track their progress through the fulfillment process.</p>
                <img src={"/wholesale/fulfillment-list.png"}/>
            </ScrollEventWrapper>
        </div>

        <div className={"row"}>
            <ScrollEventWrapper className="wholesale-feature left" addOnTrigger={["swoop-right"]}>
                <h2>Scan inventory barcodes on your smart phone.</h2>
                <p>Why should your limiting factor be how fast you can scan your packages onto and off of the shelves?</p>
                <p>Work in parallel, with each team member loading the scan tool on their phone.</p>
                <img src={"/wholesale/fulfillment-scan-tool.png"} height={386} width={200}/>
            </ScrollEventWrapper>
            <ScrollEventWrapper className="wholesale-feature middle" addOnTrigger={["swoop-left"]}>
                <h2>Allocate inventory to salespeople to prevent double-selling.</h2>
                <p>The dreaded double-sale. Have you ever had to go back and adjust a manifest because the package that was manifested was sold out from under you?</p>
                <p>That doesn't have to happen. Allocate packages to sales people so they can only sell what's allocated to them.</p>
                <img src={"/wholesale/package-allocation.png"}/>
            </ScrollEventWrapper>
            <ScrollEventWrapper className="wholesale-feature right" addOnTrigger={["swoop-right"]}>
                <h2>Plug and play integrations with metrc (much more coming soon)</h2>
                <p>Click a button. It's that easy.</p>
                <p>Your Metrc data will sync since your inception date. It will stay up to date with the vast majority of packages appearing less than 60 seconds from the time they appeared on Metrc.</p>
                <p>More integrations are coming including Dutchie, Treez, Blaze, Biotrack, and more.</p>
                <img src={"/wholesale/customers-and-contacts.png"}/>
            </ScrollEventWrapper>
        </div>

        <div className={"row"}>
            <ScrollEventWrapper className="wholesale-feature left" addOnTrigger={["swoop-left"]}>
                <h2>Build and export wholesale menus for your customers.</h2>
                <p>Keep your customers up-to-date with salesperson-specific (or global) public online menus.</p>
                <img src={"/wholesale/menu-export.png"}/>
            </ScrollEventWrapper>
            <ScrollEventWrapper className="wholesale-feature middle" addOnTrigger={["swoop-right"]}>
                <h2>Edit product masters.</h2>
                <p>Update the unit price, unit cost, category information, names, pricing tiers, and other information about your products. The changes are reflected as you make them throughout the platform.</p>
                <img src={"/wholesale/product-master.png"}/>
            </ScrollEventWrapper>
            <ScrollEventWrapper className="wholesale-feature right" addOnTrigger={["swoop-left"]}>
                <h2>Configure tiered and special discounts.</h2>
                <p>THCa discounts, tiered discounts, and discount overrides are available. You can apply them or override them with a few clicks and keystrokes.</p>
                <img src={"/wholesale/discounts.png"}/>
            </ScrollEventWrapper>
        </div>

        <div className={"row"}>
            <h1>But we haven't told you the best part (keep going)...</h1>
        </div>
    </div>
}


export default Wholesale;