import { Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MetrcAccount from "../../../components/forms/MetrcAccount.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchMetrcAccounts, setMetrcAccounts } from "../../../redux/models/integrations/Action.js";
import { ABORTED } from "../../../redux/constants.js";
import { showInformationalPopup } from '../../../redux/notifications/Action.js';
import {
    updateAccountStatus
} from "../../../redux/user/Action.js";
import { isNullOrUndefinedOrEmpty } from "../../../utils.js";
import Loading from "../../../components/elements/Loading.js";


const Setup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectedOrganization = useSelector(state => state.organizationReducer.selectedOrganization);
    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const accountStatus = useSelector(state => state.userReducer.loggedInUser.accountStatuses.find(a => {
        return isNullOrUndefinedOrEmpty(selectedOrganization) ? {} : a.organization_id === selectedOrganization.id;
    }));

    useEffect(() => {
        const abortController = new AbortController();

        dispatch(fetchMetrcAccounts({}, abortController)).then((fetchedMetrcAccounts) => {
            setMetrcAccounts(fetchedMetrcAccounts);
        }).catch(err => {
            if (err !== ABORTED) {
                console.error(err);
            }
        });
        return () => {
            abortController.abort()
        }
    }, [loggedInUser.id])

    if (isNullOrUndefinedOrEmpty(selectedOrganization)) {
        return <Loading />
    }

    return <>
        <Container className={"workspace"}>
            <Grid container spacing={2}>
                <Grid item xs={6} className={"left-part"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <h1 className="catchline">Enter your Metrc account details</h1>
                            <h2>Then we'll start pulling products and inventory so they're ready for you to sell</h2>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} className="right-part">
                    <Grid container spacing={2} className="metrc-form">
                        <Grid item xs={12}>
                            <MetrcAccount
                                create={true}
                                onCreate={(newAccount) => {
                                    if (isNullOrUndefinedOrEmpty(accountStatus) || !accountStatus.has_integration) {  // First integration!
                                        dispatch(updateAccountStatus({ ...accountStatus, integration: true }));
                                        dispatch(showInformationalPopup(
                                            "You've added an integration.",
                                            "The last bit of the setup is really important. We just need to know in which rooms you hold your active inventory for wholesale.",
                                            () => {
                                                window.location = process.env.REACT_APP_WHOLESALE_URL + "/locations/list/";
                                            },
                                            () => {
                                                window.location = process.env.REACT_APP_WHOLESALE_URL + "/locations/list/";
                                            }
                                        ));
                                    }
                                    navigate("/integrations/metrc/list");
                                }}
                                onUpdate={(updatedAccount) => {
                                    console.log("Updated")
                                }}
                                onDelete={(accountId) => {
                                    console.log("Deleted")
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </>
}


export default Setup;
