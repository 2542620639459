import {React} from "react";
import {Subscriptions} from "./Subscriptions.js";
import {Title as TitleElement} from "./Title.js";
import {Charts} from "./charts/Charts.js";
import {Wholesale} from "./Wholesale.js";
import {AllInclusive as AllInclusiveIcon} from "@mui/icons-material";

import {
    ArrowDownward as ArrowDownwardIcon
} from "@mui/icons-material";
import ScrollEventWrapper from "../../components/elements/ScrollEventWrapper.js";


export const Advertiser = () => {
    const getScrollTo = (elementId) => {
        return (event) => {
            event.preventDefault();
            document.getElementById(elementId).scrollIntoView({
                behavior: "smooth",
                block: "center"
            });
            return false;
        }
    }

    return (
    <div className={"home"}>
            <div className={"main-container"}>
                <h1 className={"brand"}>Canold</h1>
                <hr className={"main-title-line"}/>
                <h3 className={"brand"}>Wholesale <AllInclusiveIcon/> Analytics <AllInclusiveIcon/> Intelligence</h3>
                <h2 className={"brand"}>
                    Liberating data, enabling business.
                    <hr/>
                    <a onClick={getScrollTo("wholesale")} href="#">Jump to wholesale</a><br/>
                    <a onClick={getScrollTo("analytics")} href={"#"}>Jump to analytics</a><br/>
                    <a onClick={getScrollTo("subscriptions")} href={"#"}>Jump to subscriptions</a><br/>
                </h2>
            </div>

            <img className="main-background" src="/main-background-1.png"/>

            <div className={"scroll-down"}>
                <ArrowDownwardIcon/>
                Scroll down
                <ArrowDownwardIcon/>
            </div>

            <TitleElement
                name={"wholesale"}
                text={"A Robust Wholesale Platform"}
                subtext={"FOR FREE. No catch. You can opt-in to one of our paid subscriptions for analytics if you want, but the choice is yours."}
                actions={<img className="weed-background-2" src={"/weed-background-2.png"}/>}
            />
            <Wholesale/>

            <TitleElement
                name={"analytics"}
                text={"Intelligent Reporting"}
                subtext={"on things that are actionable for your business"}
            />
            <Charts/>

            <TitleElement
                name={"subscriptions"}
                text={"Subscription Plans Available Soon."}
                subtext={`Start and account to join the waitlist.`}
            />

            <div className="blue-rect-1 rect-fade-in"/>
            <div className="blue-rect-2 rect-fade-in"/>
            <div className="blue-rect-3 rect-fade-in"/>
            <div className="blue-rect-4 rect-fade-in"/>
            <div className="blue-rect-5 rect-fade-in"/>
            <div className="blue-rect-6 rect-fade-in"/>
            <div className="blue-rect-7 rect-fade-in"/>
            <div className="blue-rect-8 rect-fade-in"/>
            <div className="blue-rect-9 rect-fade-in"/>
            <div className="blue-rect-10 rect-fade-in"/>
            <div className="orange-rect-1 rect-fade-in"/>
            <div className="orange-rect-2 rect-fade-in"/>
            <div className="orange-rect-3 rect-fade-in"/>
            <div className="orange-rect-4 rect-fade-in"/>
            <div className="orange-rect-5 rect-fade-in"/>
            <div className="orange-rect-6 rect-fade-in"/>

            <ScrollEventWrapper className="blue-rect-11" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="blue-rect-12" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="blue-rect-13" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="blue-rect-14" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="blue-rect-15" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="blue-rect-16" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="blue-rect-17" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="blue-rect-18" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="blue-rect-19" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="blue-rect-20" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="blue-rect-21" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="blue-rect-22" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="blue-rect-23" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="blue-rect-24" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="blue-rect-25" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-7" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-8" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-9" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-10" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-11" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-12" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-13" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-14" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-15" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-16" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-17" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-18" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-19" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-20" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-21" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-22" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-23" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-24" addOnTrigger={["rect-fade-in"]}/>
            <ScrollEventWrapper className="orange-rect-25" addOnTrigger={["rect-fade-in"]}/>
        </div>
    );
};