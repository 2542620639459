import { Container, Grid } from "@mui/material";
import TextInput from "../../components/elements/TextInput.js";
import React, { useState } from "react";
import SubmitButton from "../../components/elements/SubmitButton.js";
import { enqueueSnackbar } from "../../redux/Actions.js";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { voteForIntegration } from "../../redux/Actions.js";
import { isNullOrUndefinedOrEmpty } from "../../utils.js";
import CreateNewOrganization from "../../components/forms/CreateNewOrganization.js";

const Setup = () => {
    const loggedInUser = useSelector(state => state.userReducer.loggedInUser.user);
    const organizations = useSelector(state => state.userReducer.loggedInUser.organizations);
    const loggedInUserIsLoading = useSelector(state => state.userReducer.loading);

    const [errors, setErrors] = useState({ "integration": "", "user_id": loggedInUser.id })
    const [formState, setFormState] = useState({ "integration": "", "user_id": loggedInUser.id });

    const dispatch = useDispatch();

    const requireOrganizations = () => {
        if (!loggedInUserIsLoading && isNullOrUndefinedOrEmpty(organizations)) {
            return <CreateNewOrganization alreadyOpen={true} />
        }
        return "";
    }

    return <>
        <div className={"main-container"}>
            {requireOrganizations()}
            <Container className="workspace">
                <Grid container spacing={2}>
                    <Grid item xs={6} className={"left-part"}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <h1 className="catchline">Choose your integration</h1>
                                <h2>Then we'll start pulling products and inventory so they're ready for you to
                                    sell</h2>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} className="right-part">
                        <Grid container spacing={4} className="integrator-choices">
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <Link to="/integrations/metrc/setup" id="integrations-metrc-setup">
                                    <img src="metrc.png" alt="metrc" className="integration-option" />
                                </Link>
                            </Grid>
                            <Grid item xs={6}>
                                <img src="leaflogix.png" alt="Dutchie" className="integration-option unavailable" />
                                <p className="teaser">Next up!</p>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <img src="blaze.png" alt="blaze" className="integration-option unavailable" />
                                <p className="teaser">Coming soon</p>
                            </Grid>
                            <Grid item xs={6}>
                                <img src="treez-integrator.png" alt="treez" className="integration-option unavailable" />
                                <p className="teaser">Coming soon</p>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} justifyItems="flex-end" justifyContent="center"
                                    alignItems="center">
                                    <Grid item xs={2}></Grid>
                                    <Grid item xs={6}>
                                        <TextInput
                                            setErrors={setErrors}
                                            type="text"
                                            name="integration"
                                            label="Vote for an integration you don't see."
                                            formState={formState}
                                            errors={errors}
                                            setFormState={setFormState} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <SubmitButton onSubmit={(click) => {
                                            click.preventDefault();
                                            dispatch(voteForIntegration(formState)).then(resp => {
                                                dispatch(enqueueSnackbar({
                                                    message: "Vote received! Thanks for the feedback.",
                                                    options: {
                                                        variant: "success",
                                                    },
                                                }));
                                            }).catch((errs) => {
                                                dispatch(enqueueSnackbar({
                                                    message: "Vote failed. Try again later.",
                                                    options: {
                                                        variant: "error",
                                                    },
                                                }));
                                                setErrors({ ...errors, ...errs });
                                            });
                                        }}>Vote</SubmitButton>
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    </>
}


export default Setup;