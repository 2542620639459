import { TextField } from "@material-ui/core";
import React from "react";
import {isNullOrUndefinedOrEmpty} from "../../utils.js";


const TextInput = ({name, label, errors, formState, setFormState, setErrors=(errs) => {}, ...props}) => {
    const error = !!errors[name];
    const helperText = error ? errors[name] : "";
    const currentValue = (formState.hasOwnProperty(name) ? formState[name] : props[name]) || "";
    return (
        <TextField
            {...props}
            fullWidth={true}
            margin="dense"
            variant="filled"
            label={label}
            id={props.id ?? name}
            value={currentValue}
            onChange={(e) => {
                setFormState({...formState, [name]: e.target.value});
                setErrors({...errors, [name]: ""});
            }}
            error={error}
            helperText={helperText}
        />
    );
}


export default TextInput;
